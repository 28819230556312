import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import React, { useState } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { AccessTime } from "@mui/icons-material";

import { defineStyles } from "../../styles/defineStyles";

const DatePickerComp: React.FC<{
  dateFrom: Dayjs;
  dateTo: Dayjs;
  selectedDateInterval: (dateTimeInterval: Dayjs[]) => void;
  readonly: boolean;
}> = (props) => {
  const styles = defineStyles({
    datePicker: {
      borderRadius: "4px",
      width: "600px",
      height: "45px",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    picker: {
      flexDirection: "reverse-row",
    },
    button: {
      ml: 4,
      width: "240px",
    },
    alertMessage: {
      mt: 3,
      mb: 1,
    },
  });

  const dateTimeNow = dayjs();

  const [dateTimeFrom, setDateTimeFrom] = useState<Dayjs | null>(
    dayjs(props.dateFrom)
  );
  const [dateTimeTo, setDateTimeTo] = useState<Dayjs | null>(
    dayjs(props.dateTo)
    //dateTimeNow.add(2, "hours")
  );

  const [isIntervalValid, setIsIntervalValid] = useState<boolean>(true);
  const [isIntervalInPast, setIsIntervalInPast] = useState<boolean>(false);
  const [isNotTimeStep, setIsNotTimeStep] = useState<boolean>(false);

  const setTimeIntervalHandler = () => {
    if (!isIntervalValid || isIntervalInPast || isNotTimeStep) return;
    props.selectedDateInterval([dateTimeFrom, dateTimeTo]);
  };

  /* useEffect(() => {
    props.selectedDateInterval([dateTimeFrom, dateTimeTo]);
  }, [dateTimeFrom, dateTimeTo]); */

  return (
    <Box>
      <Stack direction="column">
        <Stack direction="row" sx={styles.datePicker}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Stack direction="row">
              <DateTimePicker
                label="Termín od"
                value={dateTimeFrom}
                ampm={false}
                inputFormat={"DD.MM.YYYY HH:mm"}
                minutesStep={15}
                onChange={(newValue) => {
                  const now = dayjs();
                  if (newValue < now) return setIsIntervalInPast(true);
                  setIsIntervalInPast(false);
                  if (
                    newValue.minute() === 15 ||
                    newValue.minute() === 0 ||
                    newValue.minute() === 30 ||
                    newValue.minute() === 45
                  ) {
                    setIsNotTimeStep(false);
                  } else {
                    return setIsNotTimeStep(true);
                  }
                  setDateTimeFrom(newValue);
                  if (newValue > dateTimeTo) return setIsIntervalValid(false);
                  setIsIntervalValid(true);
                }}
                renderInput={(params) => (
                  <TextField variant="standard" {...params} />
                )}
                InputAdornmentProps={{ position: "start" }}
                components={{ OpenPickerIcon: AccessTime }}
                readOnly={props.readonly}
              />
              <DateTimePicker
                label="Termín do"
                value={dateTimeTo}
                ampm={false}
                inputFormat={"DD.MM.YYYY HH:mm"}
                minutesStep={15}
                onChange={(newValue) => {
                  if (
                    newValue.minute() === 15 ||
                    newValue.minute() === 0 ||
                    newValue.minute() === 30 ||
                    newValue.minute() === 45
                  ) {
                    setIsNotTimeStep(false);
                  } else {
                    return setIsNotTimeStep(true);
                  }
                  setDateTimeTo(newValue);
                  if (newValue < dateTimeFrom) return setIsIntervalValid(false);
                  setIsIntervalValid(true);
                }}
                renderInput={(params) => (
                  <TextField variant="standard" {...params} sx={{ ml: 3 }} />
                )}
                InputAdornmentProps={{ position: "start" }}
                components={{ OpenPickerIcon: AccessTime }}
                readOnly={props.readonly}
              />
            </Stack>
          </LocalizationProvider>
          {!props.readonly && (
            <Button
              variant="contained"
              sx={styles.button}
              onClick={setTimeIntervalHandler}
            >
              Nastavit termín
            </Button>
          )}
        </Stack>
        {!isIntervalValid && (
          <Typography variant="body2" color="error" sx={styles.alertMessage}>
            Termín začátku musí nastat dříve, než termín konce.
          </Typography>
        )}
        {isIntervalInPast && (
          <Typography variant="body2" color="error" sx={styles.alertMessage}>
            Schůzka nemůže začínat v minulosti.
          </Typography>
        )}
        {isNotTimeStep && (
          <Typography variant="body2" color="error" sx={styles.alertMessage}>
            Termín schůzky je možné zadat v 15 minutových intervalech.
          </Typography>
        )}
      </Stack>
    </Box>
  );
};

export default DatePickerComp;
