import React from "react";

import { defineStyles } from "../../styles/defineStyles";
import AppBarCustom, { BreadCrumbsItem } from "../ui/AppBarCustom";

import NotificationTable from "./NotificationTable";

const NotificationPage: React.FC = () => {
  const styles = defineStyles({});
  const notificationBreadCrumb: BreadCrumbsItem[] = [
    { name: "Notifikace", path: "/notifications" },
  ];

  return (
    <React.Fragment>
      <AppBarCustom
        isDetail={false}
        title="Notifikační centrum"
        breadCrumb={notificationBreadCrumb}
        pathToAdd=""
        addOrRemoveButtonCategory="notification"
        isSearchFunctionActive={false}
      />
      <NotificationTable />
    </React.Fragment>
  );
};

export default NotificationPage;
