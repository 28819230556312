import { Box, CardMedia, Chip, TextField, Typography } from "@mui/material";
import React from "react";

import { RoomDTO } from "../../api/types";
import { defineStyles } from "../../styles/defineStyles";
import CalendarLink from "../ui/CalendarLink";
import Chips from "../ui/Chips";

const RoomDetailItem: React.FC<{
  room: RoomDTO;
}> = (props) => {
  const styles = defineStyles({
    main: {
      display: "flex",
      flexDirection: "column",
      ml: "calc((100vw - 788px)/2)",
      mt: 3,
      width: "500px",
    },
    title: {
      fontSize: "24px",
      fontWeight: "400",
      mb: 3,
    },
    statusBar: {
      display: "flex",
      flexDirection: "row",
      mt: 3,
    },
    chipsBox: {
      mt: 3,
    },
    chipsTitle: {
      m: 0,
      p: 0,
      fontSize: "12px",
    },
    roomItem: {
      mt: 3,
    },
  });

  /* let statusText = "";
  if (props.room.status) {
    statusText = "Dostupná";
  } else {
    statusText = "Nedostupná";
  } */

  return (
    <Box sx={styles.main}>
      <Typography sx={styles.title}>{props.room.name}</Typography>
      <CardMedia component="img" height="200" image={props.room.imgUrl} />
      <Box sx={styles.statusBar}>
        {/* <Chip
          label={statusText}
          variant="filled"
          color={props.room.status ? "success" : "error"}
        /> */}
        <div className="space"></div>
        {props.room.id && <CalendarLink room={props.room} />}
      </Box>
      <Box sx={styles.chipsBox}>
        <Typography sx={styles.chipsTitle}>Vybavení</Typography>
        <Chips chipArray={props.room.equipment} />
      </Box>
      <TextField
        id="maxCapacity"
        label="Maximální kapacita lidí"
        value={props.room.capacity}
        variant="outlined"
        multiline
        maxRows={1}
        inputProps={{ readOnly: true }}
        sx={styles.roomItem}
      />
      <TextField
        id="roomDescription"
        label="Popis místnosti"
        value={props.room.description}
        variant="outlined"
        multiline
        inputProps={{ readOnly: true }}
        sx={styles.roomItem}
      />
      <TextField
        id="calendarUrl"
        label="Odkaz na kalendář"
        value={
          props.room.calendarUrl
            ? props.room.calendarUrl
            : "Odkaz na kalendář není zadán."
        }
        variant="outlined"
        multiline
        inputProps={{ readOnly: true }}
        sx={styles.roomItem}
      />
    </Box>
  );
};

export default RoomDetailItem;
