import { Theme } from "@mui/material/styles";
import { SxProps } from "@mui/material";
import React, { MouseEventHandler } from "react";
import { LoadingButton } from "@mui/lab";

import { AsyncState } from "../../hooks/useSafeAsync";
import { useDebounce } from "../../hooks/useDebounce";

type SmartButtonProps = {
  children: React.ReactNode;
  state?: AsyncState<any>;
  onClick?: MouseEventHandler<any>;
  primary?: boolean;
  variant?: "text" | "outlined" | "contained";
  color?: "primary" | "secondary" | "error" | "success";
  sx?: SxProps<Theme>;
};

export const AsyncButton = (props: SmartButtonProps) => {
  const pending = props?.state?.pending ?? false;

  // pending na true se nastavi hned, pending na false az po 500ms
  // tudis se tlacitko bude tocit vzdy minimalne 500ms
  const debouncedPending = useDebounce(pending, pending ? 0 : 500);

  return (
    <LoadingButton
      sx={props.sx}
      color={props.color ? props.color : "primary"}
      onClick={props.onClick}
      variant={
        props.variant
          ? props.variant
          : props.primary ?? true
          ? "contained"
          : "outlined"
      }
      type={props.onClick ? "button" : "submit"}
      loading={debouncedPending || pending}
    >
      {props.children}
    </LoadingButton>
  );
};
