import i18next from "i18next";
import React, { useContext } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { BrowserRouter, useLocation } from "react-router-dom";

import { AlertsBar } from "./components/AlertsBar";
import { Content } from "./components/Content";
import { AlertProvider } from "./components/contexts/AlertContext";
import AuthContext from "./components/contexts/AuthContext";
import { Menu } from "./components/menu/Menu";
import Navigation from "./components/Navigation";
import Login from "./components/login/Login";
import { UiContextProvider } from "./components/contexts/UiContext";
import UserActualization from "./components/login/UserActualization";
import LoginSetPassword from "./components/login/LoginSetPassword";

function App() {
  const { t } = useTranslation();
  const notLoggedMessage = t(
    "greentable.common.notLogged",
    "Pro práci v této části se musíte přihlásit"
  );
  const notAllowedMessage = t(
    "greentable.common.notAllowed",
    "Váš profil neumožňuje práci v této části."
  );

  const ctx = useContext(AuthContext);
  const userId = parseInt(
    new URL(window.location.href).searchParams.get("userId")
  );
  const token = new URL(window.location.href).searchParams.get("token");
  /* console.log("url " + window.location.href);
  console.log("search params " + new URL(window.location.href).searchParams);
  console.log("Url params " + userId + " / " + token); */

  return (
    <div>
      <HelmetProvider>
        <Helmet></Helmet>
        <UiContextProvider>
          <AlertProvider>
            <BrowserRouter key={i18next.language} basename="/">
              {userId != null && token != null && (
                <LoginSetPassword userId={userId} token={token} />
              )}
              {!ctx.isLoggedIn && (userId == null || token == null) && (
                <Login />
              )}
              {ctx.isLoggedIn &&
                !ctx.isLoggedAdmin &&
                (!ctx.loggedUser.userDTO.firstName ||
                  !ctx.loggedUser.userDTO.lastName ||
                  !ctx.loggedUser.userDTO.phone) && <UserActualization />}
              {((ctx.isLoggedIn &&
                ctx.loggedUser.userDTO.firstName &&
                ctx.loggedUser.userDTO.lastName &&
                ctx.loggedUser.userDTO.phone) ||
                ctx.isLoggedAdmin) && (
                <>
                  <Menu />
                  <Navigation />
                  <Content />
                </>
              )}
            </BrowserRouter>
            <AlertsBar />
          </AlertProvider>
        </UiContextProvider>
      </HelmetProvider>
    </div>
  );
}

export default App;
