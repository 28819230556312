import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Link,
} from "@mui/material";
import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  FormContainer,
  PasswordElement,
  TextFieldElement,
} from "react-hook-form-mui";

import { defineStyles } from "../../styles/defineStyles";
import AuthContext from "../contexts/AuthContext";
import { useRequiredValidation } from "../models/settings";
import { useSafeAsync } from "../../hooks/useSafeAsync";
import { useApiClient } from "../../api/useApiClient";
import {
  ChangePasswordDTO,
  ChangePasswordRequestDTO,
  LoginUserDTO,
} from "../../api/types";
import AlertContext from "../contexts/AlertContext";
import { useMinLengthValidation } from "../form/validations/useMinLengthValidation";
import { useMaxLengthValidation } from "../form/validations/useMaxLengthValidation";
import UiContext from "../contexts/UiContext";

import GoogleButton, { GoogleScript } from "./GoogleButton";
import "../../index.css";

type LoginPhase = "login" | "request" | "change";
type LoginFormType =
  | LoginUserDTO
  | ChangePasswordDTO
  | ChangePasswordRequestDTO;

const style = defineStyles({
  all: {
    width: "100vw",
    height: "100vh",
    position: "relative",
  },
  card: {
    position: "absolute",
    top: "50%",
    left: "50%",
    width: "350px",
    height: "auto",
    transform: "translate(-50%,-50%)",
    p: 2,
  },
  formGroup: {},
  textField: {
    mb: 2,
    width: "100%",
  },
  button: {
    width: "100%",
    mb: 2,
  },
  link: {
    pl: 2,
  },
});

const Login = () => {
  const ctx = useContext(AuthContext);
  const { refresh } = useContext(UiContext);
  const api = useApiClient();
  const { showSuccess } = useContext(AlertContext);
  const [phase, setPhase] = useState<LoginPhase>("login");

  const gotoRequest = useCallback(() => setPhase("request"), []);
  const gotoLogin = useCallback(() => setPhase("login"), []);
  const gotoChange = useCallback(() => setPhase("change"), []);

  const isLogin = phase === "login";
  const isRequest = phase === "request";
  const isChange = phase === "change";

  const [loginCall, loginState] = useSafeAsync(api.loginUser);
  const [changePasswordRequestCall, changePasswordRequestState] = useSafeAsync(
    api.changePasswordRequest
  );
  const [changePasswordCall, changePasswordState] = useSafeAsync(
    api.changePassword
  );

  const required = useRequiredValidation();
  const minPasswordLength = useMinLengthValidation(8);
  const maxPasswordLength = useMaxLengthValidation(128);

  useEffect(() => {
    if (loginState.value) {
      ctx.setLoggedUser(loginState.value);
    }
  }, [ctx, loginState.value]);

  const handleForm = useCallback(
    async (data: LoginFormType) => {
      if (isLogin) {
        loginCall(data as LoginUserDTO);
      }
      if (isRequest) {
        changePasswordRequestCall(data as ChangePasswordRequestDTO);
        gotoChange();
      }
      if (isChange) {
        const result = await changePasswordCall(data as ChangePasswordDTO);
        if (typeof result === "boolean" && result) {
          showSuccess("Heslo bylo změněno", "Nyní se můžete přihlásit");
          gotoLogin();
        }
      }
    },
    [
      changePasswordCall,
      changePasswordRequestCall,
      gotoChange,
      gotoLogin,
      isChange,
      isLogin,
      isRequest,
      loginCall,
      showSuccess,
    ]
  );

  return (
    <Box sx={style.all}>
      <GoogleScript />
      <Card sx={style.card} elevation={3}>
        {isLogin && (
          <CardHeader
            title="Přihlášení"
            subheader="Začněte rezervovat místnosti v GreenTable."
          />
        )}
        {isRequest && (
          <CardHeader
            title="Krok 1/2 - zaslání kódu"
            subheader="Zadejte váš přihlašovací email, na který vám zašleme jednorázový kód pro změnu hesla. Kód nikomu neukazujte ani nepřeposílejte!"
          />
        )}
        {isChange && (
          <CardHeader
            title="Krok 2/2 - změna hesla"
            subheader="Pokud email v naší databázi existuje, zaslali jsme vám na něj kód, který prosím zkopírujte do následujícího pole."
          />
        )}

        <CardContent>
          <FormContainer defaultValues={{}} onSuccess={handleForm}>
            {(isLogin || isRequest) && (
              <TextFieldElement
                name="email"
                validation={required}
                label="Email"
                autoComplete={"email"}
                sx={style.textField}
              />
            )}
            {isLogin && (
              <PasswordElement
                name="password"
                validation={{
                  ...required,
                  ...minPasswordLength,
                  ...maxPasswordLength,
                }}
                label="Heslo"
                autoComplete={"current-password"}
                sx={style.textField}
              />
            )}
            {isChange && (
              <TextFieldElement
                name="token"
                validation={required}
                label="Kód pro změnu hesla"
                autoComplete={"one-time-code"}
                sx={style.textField}
              />
            )}
            {isChange && (
              <PasswordElement
                name="newPassword"
                validation={{
                  ...required,
                  ...minPasswordLength,
                  ...maxPasswordLength,
                }}
                label="Nové heslo"
                autoComplete={"new-password"}
                sx={style.textField}
              />
            )}
            <Button type="submit" variant="contained" sx={style.button}>
              {isLogin && "Přihlášení"}
              {isRequest && "Vyžádat kód"}
              {isChange && "Změnit heslo"}
            </Button>

            {isLogin && <GoogleButton sx={style.button}></GoogleButton>}
          </FormContainer>
        </CardContent>
        <CardActions>
          {isLogin && (
            <Link underline="hover" sx={style.link} onClick={gotoRequest}>
              Zapomenuté heslo
            </Link>
          )}
          {!isLogin && (
            <Link underline="hover" sx={style.link} onClick={gotoLogin}>
              Zpět na přihlášení
            </Link>
          )}
        </CardActions>
      </Card>
    </Box>
  );
};

export default Login;
