import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { FormContainer, TextFieldElement } from "react-hook-form-mui";
import { useParams } from "react-router-dom";

import { defineStyles } from "../../styles/defineStyles";
import { useRequiredValidation } from "../models/settings";

const CustomDialog: React.FC<{
  openDialog: boolean;
  closed: () => void;
  title: string;
  subtitle: string;
  commentLabel?: string;
  commentPlaceholder?: string;
  buttonText: string;
  onButtonClick: (comment: string) => void;
}> = (props) => {
  const styles = defineStyles({
    main: {},
    comment: {
      mt: 2,
      width: "100%",
    },
    buttons: {
      mt: 2,
    },
  });
  const requestData = useParams();
  const requestId = requestData.id;
  const required = useRequiredValidation();
  const [open, setOpen] = useState<boolean>(props.openDialog);
  const [deneidComment, setDeneidComment] = useState<string>("");
  const handleClose = () => {
    setOpen(false);
    props.closed();
  };
  const submitHandler = (data: { deniedComment: string }) => {
    props.onButtonClick(data.deniedComment);
  };
  return (
    <React.Fragment>
      <Dialog open={open} onClose={handleClose} sx={styles.main}>
        <DialogTitle>{props.title}</DialogTitle>
        <DialogContent>
          <Typography>{props.subtitle}</Typography>
          <FormContainer defaultValues={{}} onSuccess={submitHandler}>
            {props.commentLabel.length > 0 && (
              <TextFieldElement
                name="deniedComment"
                label={props.commentLabel}
                id="deniedComment"
                value={deneidComment}
                placeholder={props.commentPlaceholder}
                validation={required}
                multiline
                rows={2}
                sx={styles.comment}
              />
            )}
            <Stack direction="row" sx={styles.buttons}>
              <div className="space"></div>
              <Button onClick={handleClose} variant="text" color="primary">
                Zpět
              </Button>
              <Button type="submit" variant="text" color="error">
                {props.buttonText}
              </Button>
            </Stack>
          </FormContainer>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default CustomDialog;
