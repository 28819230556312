import { Alert, Box, CircularProgress } from "@mui/material";
import React, { useMemo } from "react";

import { AsyncState } from "../../hooks/useSafeAsync";
import { destilErrorMessage } from "../../api/destilErrorMessage";

type LoadingGuardProps = {
  children: React.ReactNode | React.ReactNode[];
  state: AsyncState<any>;
};

/**
 * Vnitrek se zobrazi jen kdyz se nepracuje a vubec je vsechno v poradku.
 *
 * @param props
 * @constructor
 */
export const LoadingGuard = (props: LoadingGuardProps) => {
  const errorMessage = useMemo(() => {
    if (props.state.error) {
      return destilErrorMessage(props.state.error);
    }
    return null;
  }, [props.state.error]);

  if (errorMessage) {
    return (
      <Box paddingTop={"3em"} paddingBottom={"5em"}>
        <Alert severity="error">{errorMessage}</Alert>
      </Box>
    );
  }

  if (props.state.pending || props.state.called == false) {
    return (
      <Box paddingTop={"3em"} paddingBottom={"5em"} textAlign={"center"}>
        <CircularProgress />
      </Box>
    );
  }
  return <>{props.children}</>;
};
