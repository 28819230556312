import React, { useCallback, useMemo, useState } from "react";

const UiContext = React.createContext({
  refresh: () => {
    //donothing
  },
});

export const UiContextProvider: React.FC<{
  children?: React.ReactNode;
}> = (props) => {
  const [_uiVersion, _setUiVersion] = useState(0);

  const refresh = useCallback(() => {
    _setUiVersion((prev) => prev + 1);
  }, []);

  const ui = useMemo(() => {
    return {
      refresh,
    };
  }, [refresh]);

  return (
    <UiContext.Provider value={ui} key={_uiVersion}>
      {props.children}
    </UiContext.Provider>
  );
};

export default UiContext;
