import { useTranslation } from "react-i18next";

export function isValidEmail(value?: string) {
  if (value == null) return false;
  if (value.length < 5) return false;
  return /^[A-Z0-9._%+-]+@([A-Z0-9.-]+\.)+[A-Z]{2,10}$/i.test(value);
}

export const useEmailValidation = () => {
  const { t } = useTranslation();

  return {
    validate: (value?: string) =>
      isValidEmail(value)
        ? undefined
        : t("common.error.email", "Vložte platnou emailovou adresu"),
  };
};
