import { Box, Card, CardContent, CardHeader } from "@mui/material";
import React, { useContext } from "react";
import { FormContainer, TextFieldElement } from "react-hook-form-mui";

import { useApiClient } from "../../api/useApiClient";
import { useSafeAsync } from "../../hooks/useSafeAsync";
import { defineStyles } from "../../styles/defineStyles";
import AlertContext from "../contexts/AlertContext";
import { AsyncButton } from "../form/AsyncButton";
import { usePhoneNumberValidation } from "../form/validations/usePhoneNumberValidation";
import { useRequiredValidation } from "../models/settings";

const UserActualization: React.FC = () => {
  const styles = defineStyles({
    all: {
      width: "100vw",
      height: "100vh",
      position: "relative",
    },
    card: {
      position: "absolute",
      top: "50%",
      left: "50%",
      width: "800px",
      minHeight: "300px",
      transform: "translate(-50%,-50%)",
      p: 2,
    },
    description: {},
    descriptionBold: {
      fontWeight: "bold",
    },
    agreement: {
      mt: 2,
      mb: 2,
    },
    formGroup: {},
    textField: {
      mb: 2,
      width: "100%",
    },
    button: {
      width: "100%",
      mb: 2,
    },
    link: {
      pl: 1,
    },
  });

  const required = useRequiredValidation();
  const phoneValidation = usePhoneNumberValidation();
  const { showSuccess } = useContext(AlertContext);
  const api = useApiClient();
  const [updateUserCall, udpdateUserState] = useSafeAsync(api.updateUser);

  const userUpdateHandler = async (data: {
    firstName: string;
    lastName: string;
    phoneNumber: string;
  }) => {
    const result = await updateUserCall({
      firstName: data.firstName,
      lastName: data.lastName,
      phone: data.phoneNumber,
    });
    if (typeof result === "object" && result) {
      showSuccess("Údaje byly aktualizovány", "");
      setTimeout(() => window.location.reload(), 250);
    }
  };

  return (
    <Box sx={styles.all}>
      <Card sx={styles.card} elevation={3}>
        <CardHeader
          title="Aktualizace osobních údajů účtu"
          subheader={
            "Doplňte prosím Vaše jméno a telefonní číslo. Na toto číslo Vás budeme kontaktovat jenom ohledně Vašich rezervací v prostoru GreenTable."
          }
        />
        <CardContent>
          <FormContainer defaultValues={{}} onSuccess={userUpdateHandler}>
            <TextFieldElement
              name="firstName"
              validation={required}
              label="Jméno"
              sx={styles.textField}
            />
            <TextFieldElement
              name="lastName"
              validation={required}
              label="Příjmení"
              sx={styles.textField}
            />
            <TextFieldElement
              name="phoneNumber"
              validation={{ ...required, ...phoneValidation }}
              label="Telefon"
              sx={styles.textField}
            />
            <AsyncButton
              variant="contained"
              sx={styles.button}
              state={udpdateUserState}
            >
              Uložit
            </AsyncButton>
          </FormContainer>
        </CardContent>
      </Card>
    </Box>
  );
};

export default UserActualization;
