import { Box } from "@mui/material";
import React, { useCallback, useContext, useEffect } from "react";

import { RequestDTO } from "../../api/types";
import { useApiClient } from "../../api/useApiClient";
import { useSafeAsync } from "../../hooks/useSafeAsync";
import { defineStyles } from "../../styles/defineStyles";
import AuthContext from "../contexts/AuthContext";
import UiContext from "../contexts/UiContext";
import { LoadingGuard } from "../form/LoadingGuard";
import AppBarCustom, { BreadCrumbsItem } from "../ui/AppBarCustom";

import EmptyRequestContent from "./EmptyRequestContent";
import RequestTable from "./RequestTable";

const RequestPage: React.FC = () => {
  const styles = defineStyles({
    tabs: {
      marginTop: "20px",
    },
  });

  const ctx = useContext(AuthContext);
  const { refresh } = useContext(UiContext);
  const api = useApiClient();

  const [allRequestsCall, allRequestsState] = useSafeAsync(
    useCallback(() => {
      if (ctx.isLoggedAdmin) {
        return api.findAllRequests({});
      }
      return api.findAllRequestsByStatus({
        userId: ctx.loggedUser.userDTO.id,
      });
    }, [ctx.isLoggedAdmin, api, ctx.loggedUser.userDTO.id])
  );

  const roomBreadCrumb: BreadCrumbsItem[] = [
    { name: "Žádosti", path: "/requests" },
  ];

  const getRequestsForUser = (userId: number, allRequests: RequestDTO[]) => {
    const userRequests: RequestDTO[] = [];
    for (let i = 0; i < allRequests.length; i++) {
      if (userId === allRequests[i].userDTO.id) {
        userRequests.push(allRequests[i]);
      }
    }
    return userRequests;
  };

  const refreshAllRequestsHandler = () => {
    refresh();
  };

  useEffect(() => {
    allRequestsCall({});
  }, [allRequestsCall]);

  return (
    <React.Fragment>
      <AppBarCustom
        isDetail={false}
        title="Žádosti"
        breadCrumb={roomBreadCrumb}
        addOrRemoveButtonCategory="request"
        pathToAdd={"/requests/new"}
        isSearchFunctionActive={false}
      />
      <LoadingGuard state={allRequestsState}>
        {allRequestsState.value && (
          <Box sx={styles.tabs}>
            {allRequestsState.value.data.length == 0 && <EmptyRequestContent />}
            {allRequestsState.value.data.length > 0 && (
              <RequestTable
                requests={
                  (ctx.isLoggedAdmin && allRequestsState.value.data) ||
                  (!ctx.isLoggedAdmin &&
                    getRequestsForUser(
                      ctx.loggedUser.userDTO.id,
                      allRequestsState.value.data
                    ))
                }
                refreshReq={refreshAllRequestsHandler}
              />
            )}
          </Box>
        )}
      </LoadingGuard>
    </React.Fragment>
  );
};

export default RequestPage;
