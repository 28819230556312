import { Box, Button, Chip, Stack, TextField, Typography } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  CheckboxElement,
  FormContainer,
  SelectElement,
  TextFieldElement,
} from "react-hook-form-mui";
import { useParams } from "react-router-dom";
import { createTextChangeRange } from "typescript";

import { RequestDTO } from "../../api/types";
import { useApiClient } from "../../api/useApiClient";
import { useSafeAsync } from "../../hooks/useSafeAsync";
import { defineStyles } from "../../styles/defineStyles";
import AuthContext from "../contexts/AuthContext";
import { LoadingGuard } from "../form/LoadingGuard";
import { useIsIntegerValidation } from "../form/validations/useIsIntegerValidation";
import { useMaxCapacityValidation } from "../form/validations/useMaxCapacityValidation";
import { useMaxLengthValidation } from "../form/validations/useMaxLengthValidation";
import { useRequiredValidation } from "../models/settings";

import CalendarLink from "./CalendarLink";
import DatePickerComp from "./DatePickerComp";

export type DetailFormData = {
  roomName: string;
  eventName: string;
  peopleCount: number;
  refreshmentCheckbox: boolean;
  eventDescripton: string;
  clientName: string;
  userEmail: string;
  userPhone: string;
  companyName: string;
  companyDescription: string;
};

const DetailForm: React.FC<{
  title: string;
  request: RequestDTO;
  isSubmitAllowed: boolean;
  onSubmitAction: (data: RequestDTO) => void;
}> = (props) => {
  const styles = defineStyles({
    main: {
      mt: 9,
      ml: "calc((100vw - 888px)/2)",
      alignItem: "center",
      width: "600px",
    },
    description: {
      color: "rgba(0,0,0,0.6)",
    },
    date: {
      width: "100%",
      mt: 3,
      display: "flex",
      flexDirection: "row",
    },
    formItem: {
      width: "100%",
      mt: 3,
    },
    calendar: {
      ml: 2,
      mt: 2,
    },
    refreshmentCheckbox: {},
    /* button: {
          mt: 3,
          justifyContent: "right",
        }, */
    chip: {
      mt: 2,
    },
    icon: {
      mr: 1,
      width: "16px",
      height: "18px",
    },
    button: {
      ml: 2,
      alignItems: "center",
    },
    buttonText: {
      fontSize: "13px",
    },
    statusLine: {
      alignItems: "baseline",
    },
  });
  const required = useRequiredValidation();
  const ctx = useContext(AuthContext);

  const data = useParams();
  const itemId = parseInt(data.id);

  const api = useApiClient();
  const [allRoomsCall, allRoomsState] = useSafeAsync(api.findAllRooms);

  const maxRequestNameLenght = useMaxLengthValidation(36);
  const isInteger = useIsIntegerValidation().validate;

  const maxCapacity = useMaxCapacityValidation(
    props.request.roomDTO.capacity ? props.request.roomDTO.capacity : 0
  ).validate;

  const [dateTimeInterval, setDateTimeInterval] = useState<Dayjs[] | null>([
    dayjs(props.request.since),
    dayjs(props.request.till),
  ]);

  const acceptedChip = (
    <Chip variant="filled" color="success" label="Potvrzená" sx={styles.chip} />
  );
  const rejectedChip = (
    <Chip variant="filled" color="error" label="Zamítnutá" sx={styles.chip} />
  );
  const undecidedChip = (
    <Chip
      variant="filled"
      color="primary"
      label="Čekání na potvrzení"
      sx={styles.chip}
    />
  );

  const getRightStatusChip = (status: string) => {
    if (status === "REQUEST") return undecidedChip;
    if (status === "ACCEPTED") return acceptedChip;
    if (status === "DECLINED") return rejectedChip;
    return <></>;
  };

  const dateTimeIntervalHandler = (interval: Dayjs[]) => {
    setDateTimeInterval([interval[0], interval[1]]);
  };
  const submitHandler = useCallback(
    (data: DetailFormData) => {
      const updatedRequest: RequestDTO = {
        id: itemId,
        name: data.eventName,
        description: data.eventDescripton,
        roomId: parseInt(data.roomName),
        since: dateTimeInterval[0].toISOString(),
        till: dateTimeInterval[1].toISOString(),
        occupancy: data.peopleCount,
        replenishment: data.refreshmentCheckbox,
        status: "ACCEPTED",
      };
      props.onSubmitAction(updatedRequest);
    },
    [props, dateTimeInterval, itemId]
  );

  const allRoomsOpt = useCallback(() => {
    if (allRoomsState.value == null) {
      return [];
    }
    return allRoomsState.value.data.map((room) => {
      return { id: room.id, label: room.name };
    });
  }, [allRoomsState.value]);

  useEffect(() => {
    allRoomsCall({});
  }, [allRoomsCall]);

  return (
    <React.Fragment>
      <Typography variant="h5" sx={styles.description}>
        {props.title}
      </Typography>
      {!ctx.isLoggedAdmin && props.title === "Žádost" && (
        <Stack direction="column">
          <Stack direction="row" sx={styles.statusLine}>
            <Typography variant="body2" sx={{ mr: 1 }}>
              Status:{" "}
            </Typography>{" "}
            {getRightStatusChip(props.request.status)}
          </Stack>
          {props.request.declineComment && (
            <TextField
              id="deniedComment"
              label="Důvod zamítnutí žádosti"
              value={props.request.declineComment}
              multiline
              rows={2}
              sx={styles.formItem}
            />
          )}
        </Stack>
      )}
      <Box sx={styles.date}>
        <DatePickerComp
          dateFrom={dateTimeInterval[0]}
          dateTo={dateTimeInterval[1]}
          selectedDateInterval={dateTimeIntervalHandler}
          readonly={true}
        />
        {props.request.roomDTO.id && (
          <Box sx={styles.calendar}>
            <CalendarLink
              room={props.request.roomDTO}
              since={dateTimeInterval[0]}
            />
          </Box>
        )}
      </Box>
      <LoadingGuard state={allRoomsState}>
        <FormContainer
          defaultValues={{
            roomName: props.request.roomDTO.id.toString(),
            eventName: props.request.name,
            peopleCount: props.request.occupancy,
            refreshmentCheckbox: props.request.replenishment,
            eventDescripton: props.request.description,
            clientName: props.request.clientId.toString(),
            userEmail: props.request.userDTO.email
              ? props.request.userDTO.email
              : "Není zadán",
            userPhone: props.request.userDTO.phone
              ? props.request.userDTO.phone
              : "Není zadán",
            companyName: props.request.clientDTO.name,
            companyDescription: props.request.clientDTO.description,
          }}
          onSuccess={submitHandler}
        >
          {allRoomsState.value && (
            <SelectElement
              name="roomName"
              validation={required}
              label="Místnost"
              sx={styles.formItem}
              options={allRoomsOpt()}
              inputProps={{ readOnly: true }}
            />
          )}
          <TextFieldElement
            name="companyName"
            validation={required}
            label="Název klienta"
            sx={styles.formItem}
            inputProps={{ readOnly: true }}
          />
          <TextFieldElement
            name="companyDescription"
            validation={required}
            label="Popis klienta"
            sx={styles.formItem}
            inputProps={{ readOnly: true }}
          />
          <TextFieldElement
            name="userEmail"
            validation={required}
            label="Uživatelský účet"
            sx={styles.formItem}
            inputProps={{ readOnly: true }}
          />
          <TextFieldElement
            name="userPhone"
            validation={required}
            label="Telefon"
            sx={styles.formItem}
            inputProps={{ readOnly: true }}
          />
          <TextFieldElement
            name="eventName"
            validation={{ ...required, ...maxRequestNameLenght }}
            label="Název události"
            sx={styles.formItem}
            inputProps={{ readOnly: !props.isSubmitAllowed }}
          />
          <TextFieldElement
            name="peopleCount"
            validation={{
              ...required,
              validate: {
                isInt: isInteger,
                maxCap: maxCapacity,
              },
            }}
            placeholder={
              "Max. " + props.request.roomDTO.capacity.toString() + "."
            }
            label="Počet lidí"
            sx={styles.formItem}
            helperText="Maximální přibližný počet očekávaných hostí události"
            inputProps={{ readOnly: !props.isSubmitAllowed }}
          />
          <Typography sx={styles.formItem}>Občerstvení z restaurace</Typography>
          <CheckboxElement
            name="refreshmentCheckbox"
            label="Mám zájem, aby me restaurace kontaktovala"
            helperText="Káva a voda jsou zdarma v rámci coworku"
            sx={styles.refreshmentCheckbox}
            inputProps={{ disabled: !props.isSubmitAllowed }}
          />
          <TextFieldElement
            name="eventDescripton"
            label="Popis události (volitelné)"
            sx={styles.formItem}
            inputProps={{ readOnly: !props.isSubmitAllowed }}
          />
          {props.title !== "Žádost" && props.isSubmitAllowed && (
            <Stack direction="row" sx={{ mt: 3 }}>
              <div className="space"></div>
              <Button
                type="submit"
                variant="contained"
                sx={styles.button}
                color="primary"
              >
                <Typography sx={styles.buttonText}>Uložit změny</Typography>
              </Button>
            </Stack>
          )}
        </FormContainer>
      </LoadingGuard>
    </React.Fragment>
  );
};

export default DetailForm;
