import { Alert, AlertTitle, Box } from "@mui/material";
import React, { useContext } from "react";

import { defineStyles } from "../styles/defineStyles";

import AlertContext from "./contexts/AlertContext";

const styles = defineStyles({
  root: {
    position: "fixed",
    right: 0,
    bottom: 0,
    width: "50%",
    maxWidth: 600,
  },
  alert: {
    margin: 1,
  },
});

export const AlertsBar: React.FC = () => {
  const context = useContext(AlertContext);

  if (context.alerts?.length === 0) {
    return null;
  }

  return (
    <Box sx={styles.root}>
      {context.alerts.map((alert) => (
        <Alert sx={styles.alert} key={alert.id} severity={alert.type}>
          <AlertTitle>{alert.message}</AlertTitle>
          <span
            dangerouslySetInnerHTML={{
              __html: alert.description?.replace(/\n/g, "<br />"),
            }}
          />
        </Alert>
      ))}
    </Box>
  );
};
