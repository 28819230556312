import { Box } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { defineStyles } from "../../styles/defineStyles";
import AuthContext from "../contexts/AuthContext";
import CustomDialog from "../ui/CustomDialog";
import AppBarCustom, { BreadCrumbsItem } from "../ui/AppBarCustom";
import DetailForm from "../ui/DetailForm";
import { useApiClient } from "../../api/useApiClient";
import { useSafeAsync } from "../../hooks/useSafeAsync";
import { LoadingGuard } from "../form/LoadingGuard";
import { RequestDTO } from "../../api/types";
import UiContext from "../contexts/UiContext";

const EditEvent: React.FC = () => {
  const styles = defineStyles({
    main: {
      mt: 9,
      ml: "calc((100vw - 888px)/2)",
      alignItem: "center",
      width: "600px",
    },
    icon: {
      mr: 1,
      width: "16px",
      height: "18px",
    },
    button: {
      ml: 2,
      alignItems: "center",
    },
    buttonText: {
      fontSize: "13px",
    },
  });
  const ctx = useContext(AuthContext);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const navigate = useNavigate();
  const path = "/events/";
  const urlData = useParams();
  const eventId = parseInt(urlData.id);
  const api = useApiClient();
  const { refresh } = useContext(UiContext);

  const [currentEventCall, currentEventState] = useSafeAsync(
    api.findRequestById
  );
  const [deleteCurrentEventCall, deleteCurrentEventState] = useSafeAsync(
    api.deleteRequest
  );
  const [updateCurrentEventCall, updateCurrentEventState] = useSafeAsync(
    api.updateRequest
  );

  const editEventBreadcrumb: BreadCrumbsItem[] = [
    { name: "Události", path: "/events" },
    { name: "Detail události", path: "" },
  ];

  const onRemoveFunctionHandler = () => {
    setShowDialog(true);
  };
  const closeDialogHandler = () => {
    setShowDialog(false);
  };

  const onEventRemoveHandler = async (comment: string) => {
    // provede odstraneni udalosti
    await deleteCurrentEventCall({
      id: eventId,
      requestId: eventId,
      deleteComment: comment,
    });
    setShowDialog(false);
    navigate(-1);
  };

  const onSubmitActionHandler = async (data: RequestDTO) => {
    // po ulozeni dojde k presmerovani na events
    const result = await updateCurrentEventCall({
      id: data.id,
      name: data.name,
      description: data.description,
      roomId: data.roomId,
      since: data.since,
      till: data.till,
      occupancy: data.occupancy,
      replenishment: data.replenishment,
      force: false,
    });
    if (result != null) {
      refresh();
      navigate(-1);
    }
  };

  useEffect(() => {
    currentEventCall({ id: eventId });
  }, [currentEventCall, eventId]);

  return (
    <React.Fragment>
      {ctx.isLoggedAdmin && (
        <React.Fragment>
          <AppBarCustom
            isDetail={true}
            title="Detail události"
            breadCrumb={editEventBreadcrumb}
            addOrRemoveButtonCategory="event"
            pathToAdd={""}
            isSearchFunctionActive={false}
            onRemoveFunction={onRemoveFunctionHandler}
          />
          <Box sx={styles.main}>
            <LoadingGuard state={currentEventState}>
              {currentEventState.value && (
                <DetailForm
                  title="Událost"
                  request={currentEventState.value}
                  isSubmitAllowed={true}
                  onSubmitAction={onSubmitActionHandler}
                />
              )}
            </LoadingGuard>
          </Box>
          {showDialog && (
            <CustomDialog
              openDialog={showDialog}
              title="Odstranění události"
              subtitle="Předtím než definitivně událost odstraníte, udejte prosím důvod. Zvýšíte tak efektivitu komunikace s klientem."
              commentLabel="Důvod odstranění události"
              commentPlaceholder=""
              closed={closeDialogHandler}
              buttonText="Odstranit událost"
              onButtonClick={onEventRemoveHandler}
            />
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default EditEvent;
