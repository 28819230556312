import { CalendarToday, Inbox, Person } from "@mui/icons-material";
import { Box, Button, Stack, Tab, Tabs, Typography } from "@mui/material";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FormContainer, TextFieldElement } from "react-hook-form-mui";

import { defineStyles } from "../../styles/defineStyles";
import EventTable from "../events/EventTable";
import CustomDialog from "../ui/CustomDialog";
import RequestTable from "../requests/RequestTable";
import AppBarCustom, { BreadCrumbsItem } from "../ui/AppBarCustom";
import UserPage from "../User/UserPage";
import { useSafeAsync } from "../../hooks/useSafeAsync";
import { useApiClient } from "../../api/useApiClient";
import { LoadingGuard } from "../form/LoadingGuard";
import UiContext from "../contexts/UiContext";
import { useRequiredValidation } from "../form/validations/useRequiredValidation";
import { AsyncButton } from "../form/AsyncButton";
import AlertContext from "../contexts/AlertContext";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`ab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}

const ClientDetail: React.FC = () => {
  const styles = defineStyles({
    basicInfo: {
      mt: 3,
      mb: 9,
      width: "500px",
    },
    infoItem: {
      mt: 3,
      width: "100%",
    },
    mainTabs: {
      width: "100%",
    },
    tabs: {},
    icon: {
      height: "24px",
      width: "24px",
      m: 0,
    },
  });

  const [value, setValue] = React.useState(0);
  const urlData = useParams();
  const api = useApiClient();
  const companyId = parseInt(urlData.id);
  const [currentCompanyCall, currentCompanyState] = useSafeAsync(
    api.findClientById
  );
  const companyDetailBreadCrumb: BreadCrumbsItem[] = [
    { name: "Klienti", path: "/clients/" },
    { name: "Detail klienta", path: "" },
  ];

  const navigate = useNavigate();
  const { refresh } = useContext(UiContext);
  const required = useRequiredValidation();
  const { showSuccess } = useContext(AlertContext);

  const [deleteClientCall, deleteClientState] = useSafeAsync(api.deleteClient);
  const [updateClientCall, updateClientState] = useSafeAsync(api.updateClient);
  const [allRequestsByClientIdCall, allRequestsByClientIdState] = useSafeAsync(
    api.findAllRequestsByClientId
  );
  const [allEventsCall, allEventsState] = useSafeAsync(
    api.findAllRequestsByStatus
  );

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const [showDialog, setShowDialog] = useState<boolean>(false);

  const onRemoveFuncitonHandler = () => {
    setShowDialog(true);
  };

  const closeHandler = () => {
    setShowDialog(false);
  };

  const changeClientHanddler = useCallback(
    async (data: { companyName: string; companyDescription: string }) => {
      //zmena nazvu/popisu spolecnosti
      const result = await updateClientCall({
        clientId: companyId,
        name: data.companyName,
        description: data.companyDescription,
      });
      if (typeof result === "object" && result) {
        refresh();
      }
    },
    [updateClientCall, refresh, companyId]
  );

  const onRemoveCompanyHandler = async () => {
    setShowDialog(false);
    // remove company and request+events
    const result = await deleteClientCall({ clientId: companyId });
    if (typeof result === "boolean" && result) {
      showSuccess(
        "Klient " +
          currentCompanyState.value.name +
          " byl včetně všech uživatelů, žádostí a událostí smazán.",
        ""
      );
    }
    if (result !== false) {
      navigate(-1);
    }
  };

  const refreshClientRequestsHandler = () => {
    refresh();
  };

  useEffect(() => {
    currentCompanyCall({ id: companyId });
    allRequestsByClientIdCall({ clientId: companyId });
    allEventsCall({ status: "ACCEPTED", clientId: companyId });
  }, [allEventsCall, allRequestsByClientIdCall, currentCompanyCall, companyId]);

  return (
    <React.Fragment>
      <LoadingGuard state={currentCompanyState}>
        {currentCompanyState.value && (
          <AppBarCustom
            isDetail={true}
            title={"Klient: " + currentCompanyState.value.name}
            breadCrumb={companyDetailBreadCrumb}
            addOrRemoveButtonCategory="client"
            pathToAdd=""
            isSearchFunctionActive={false}
            onRemoveFunction={onRemoveFuncitonHandler}
          />
        )}
        {currentCompanyState.value && (
          <Box sx={styles.basicInfo}>
            <Typography variant="h5">Základní informace</Typography>
            <FormContainer
              defaultValues={{
                companyName: currentCompanyState.value.name,
                companyDescription: currentCompanyState.value.description
                  ? currentCompanyState.value.description
                  : "-",
              }}
              onSuccess={changeClientHanddler}
            >
              <TextFieldElement
                variant="outlined"
                label="Název společnosti"
                id="companyName"
                name="companyName"
                validation={required}
                sx={styles.infoItem}
              />
              <TextFieldElement
                variant="outlined"
                label="Popis společnosti"
                id="companyDescription"
                name="companyDescription"
                validation={required}
                sx={styles.infoItem}
                multiline
                rows={2}
              />
              <Stack direction="row" sx={styles.infoItem}>
                <div className="space"></div>
                <AsyncButton state={updateClientState}>
                  Uložit změny
                </AsyncButton>
              </Stack>
            </FormContainer>
          </Box>
        )}
      </LoadingGuard>
      <Box sx={styles.mainTabs}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="tabs"
            sx={styles.tabs}
          >
            <Tab
              label="Účty"
              icon={<Person sx={styles.icon} />}
              iconPosition="start"
              {...a11yProps(0)}
            />
            <Tab
              label="Žádosti"
              icon={<CalendarToday sx={styles.icon} />}
              iconPosition="start"
              {...a11yProps(1)}
            />
            <Tab
              label="Události"
              icon={<Inbox sx={styles.icon} />}
              iconPosition="start"
              {...a11yProps(2)}
            />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <UserPage />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <LoadingGuard state={allRequestsByClientIdState}>
            {allRequestsByClientIdState.value && (
              <RequestTable
                requests={allRequestsByClientIdState.value.data}
                refreshReq={refreshClientRequestsHandler}
              />
            )}
          </LoadingGuard>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <LoadingGuard state={allEventsState}>
            {allEventsState.value && (
              <EventTable events={allEventsState.value.data} />
            )}
          </LoadingGuard>
        </TabPanel>
      </Box>
      {showDialog && (
        <CustomDialog
          openDialog={showDialog}
          closed={closeHandler}
          title="Odstranit klienta"
          subtitle="Tímto potvrzujete odstranění klienta se systému. Po odstranění klienta, se automaticky odstraní i všechny uživatelské účty a potvrzené události z kalendáře."
          commentLabel=""
          commentPlaceholder=""
          buttonText="Odstranit klienta"
          onButtonClick={onRemoveCompanyHandler}
        />
      )}
    </React.Fragment>
  );
};

export default ClientDetail;
