import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from "@mui/material";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  ChangeHandler,
  CheckboxElement,
  FormContainer,
  SelectElement,
  TextFieldElement,
} from "react-hook-form-mui";
import { Link, useNavigate } from "react-router-dom";
import dayjs, { Dayjs } from "dayjs";

import { defineStyles } from "../../styles/defineStyles";
import AuthContext from "../contexts/AuthContext";
import AppBarCustom, { BreadCrumbsItem } from "../ui/AppBarCustom";
import { useRequiredValidation } from "../models/settings";
import RoomTabs from "../rooms/RoomTabs";
import DatePickerComp from "../ui/DatePickerComp";
import { useApiClient } from "../../api/useApiClient";
import { useSafeAsync } from "../../hooks/useSafeAsync";
import UiContext from "../contexts/UiContext";
import AlertContext from "../contexts/AlertContext";
import { RequestDTO, RoomDTO } from "../../api/types";
import { useMaxCapacityValidation } from "../form/validations/useMaxCapacityValidation";
import { useMaxLengthValidation } from "../form/validations/useMaxLengthValidation";
import { useIsIntegerValidation } from "../form/validations/useIsIntegerValidation";
import { AsyncButton } from "../form/AsyncButton";

type NewRequestData = {
  eventName: string;
  peopleCount: number;
  eventDescripton: string;
  refreshmentCheckbox: boolean;
  companyId: number;
  userId: number;
};

const NewRequest: React.FC<{
  prevRequest?: RequestDTO;
  resultData?: (resultData: RequestDTO) => void;
}> = (props) => {
  const styles = defineStyles({
    stepperBox: {
      mt: 3,
      width: "100%",
      height: "62px",
      mb: 3,
      position: "relative",
    },
    stepper: {
      position: "absolute",
      left: "50%",
      top: "50%",
      width: "396px",
      height: "62px",
      transform: "translate(-50%, -50%)",
    },
    description: {
      color: "rgba(0,0,0,0.6)",
    },
    icon: {
      color: "rgba(0,0,0,0.6)",
      ml: 1,
      mr: 2,
      width: "16px",
      height: "16px",
    },
    datePicker: {
      mt: 3,
      mb: 11,
      p: 2,
      background: "rgba(25, 118, 210, 0.04)",
      width: "600px",
      height: "45px",
    },
    blockPartTwo: {
      mt: 9,
      ml: "calc((100vw - 788px)/2)",
      alignItem: "center",
      width: "500px",
    },
    formItem: {
      width: "100%",
      mt: 3,
    },
    refreshmentCheckbox: {},
    button: {
      mt: 3,
      justifyContent: "right",
    },
    modalTextBold: {
      fontWeight: "bold",
    },
    modalRefreshment: {
      mt: 3,
    },
  });

  const timeNow = dayjs();
  const hourNow = timeNow.hour();
  const nextHour = hourNow + 1;
  let nextHourTime = timeNow.set("hour", nextHour);
  nextHourTime = nextHourTime.set("minute", 0);
  const ctx = useContext(AuthContext);
  const { refresh } = useContext(UiContext);
  const { showSuccess } = useContext(AlertContext);
  const navigate = useNavigate();
  const newRequestBreadCrumb: BreadCrumbsItem[] = [
    { name: "Žádosti", path: "/requests" },
    { name: "Nová", path: "" },
  ];

  const [activeStep, setActiveStep] = useState<number>(0);
  const steps: string[] = ["Základní info", "Formulář žádosti", "Potvrzení"];
  const [selectedRoom, setSelectedRoom] = useState<RoomDTO | null>(null);
  const [newRequest, setNewRequest] = useState<RequestDTO | null>(
    props.prevRequest ? props.prevRequest : null
  );

  const required = useRequiredValidation();
  const maxRequestNameLenght = useMaxLengthValidation(36);
  const isInteger = useIsIntegerValidation().validate;

  const maxCapacity = useMaxCapacityValidation(
    selectedRoom ? selectedRoom.capacity : 0
  ).validate;

  const [dateTimeInterval, setDateTimeInterval] = useState<Dayjs[] | null>(
    null
  );
  const [isDateTimeSet, setIsDateTimeSet] = useState<boolean>(false);

  const api = useApiClient();
  const [allClientsCall, allClientsState] = useSafeAsync(api.findAllClients);
  const [allUsersForClientCall, allUsersForClientState] = useSafeAsync(
    api.findAllUsersByClientId
  );
  const [newRequestCall, newRequestState] = useSafeAsync(api.createRequest);

  const onRoomSelectedHandler = useCallback(
    (room: RoomDTO) => {
      let clientId: number = null;
      let userId: number = undefined;
      if (ctx.isLoggedAdmin) {
        clientId = undefined;
        userId = ctx.loggedUser.userDTO.id;
      } else {
        clientId = ctx.loggedUser.userDTO.clientId;
        userId = ctx.loggedUser.userDTO.id;
      }
      setActiveStep((activestep) => activestep + 1);
      setSelectedRoom(room);
      setNewRequest((prevData) => {
        return {
          ...prevData,
          roomId: room.id,
          clientId: clientId,
          since: dateTimeInterval[0].toISOString(),
          till: dateTimeInterval[1].toISOString(),
          userId: userId,
          status: "INCOMPLETE",
        };
      });
    },
    [ctx, dateTimeInterval]
  );

  const submitHandler = useCallback(
    (data: NewRequestData) => {
      setActiveStep((activestep) => activestep + 1);
      setOpen(true);
      setNewRequest((prevData) => {
        let description = "";
        let newCompanyId = 0;
        let newUserId = 0;
        if (data.eventDescripton) description = data.eventDescripton;
        if (data.companyId && data.userId && ctx.isLoggedAdmin) {
          newCompanyId = data.companyId;
          newUserId = data.userId;
          return {
            ...prevData,
            name: data.eventName,
            description: description,
            occupancy: data.peopleCount,
            replenishment: data.refreshmentCheckbox,
            since: dateTimeInterval[0].toISOString(),
            till: dateTimeInterval[1].toISOString(),
            clientId: newCompanyId,
            userId: newUserId,
          };
        } else {
          return {
            ...prevData,
            name: data.eventName,
            description: description,
            occupancy: data.peopleCount,
            replenishment: data.refreshmentCheckbox,
            since: dateTimeInterval[0].toISOString(),
            till: dateTimeInterval[1].toISOString(),
          };
        }
      });
    },
    [dateTimeInterval, ctx.isLoggedAdmin]
  );

  const [open, setOpen] = useState<boolean>(true);
  const handleClose = () => {
    setActiveStep((activestep) => activestep - 1);
    setOpen(false);
  };

  const addNewRequestHandler = async () => {
    const result = await newRequestCall({
      name: newRequest.name,
      description: newRequest.description,
      roomId: newRequest.roomId,
      userId: newRequest.userId,
      clientId: newRequest.clientId,
      since: newRequest.since,
      till: newRequest.till,
      occupancy: newRequest.occupancy,
      replenishment: newRequest.replenishment,
      force: false,
    });
    if (props.resultData) {
      if (typeof result === "object" && result) {
        props.resultData(result);
        navigate("/requests/");
      }
    }
  };

  const dateTimeIntervalHandler = (interval: Dayjs[]) => {
    setDateTimeInterval(interval);
    setIsDateTimeSet(true);
  };

  const backButtonHandler = () => {
    setActiveStep(activeStep - 1);
  };

  const allClients = useMemo(() => {
    if (allClientsState.value == null) {
      return [];
    }
    return allClientsState.value.data.map((client) => {
      return { id: client.id, label: client.name };
    });
  }, [allClientsState.value]);

  const allUsers = useMemo(() => {
    if (allUsersForClientState.value == null) {
      return [];
    }
    return allUsersForClientState.value.data.map((user) => {
      return { id: user.id, label: user.email };
    });
  }, [allUsersForClientState.value]);

  const onChangeClientHandler = (event: ChangeHandler) => {
    allUsersForClientCall({ clientId: parseInt(event.toString()) });
  };

  if (props.prevRequest) {
    console.log("tady posilam predchozi z " + JSON.stringify(newRequest));
  }

  useEffect(() => {
    if (ctx.isLoggedAdmin) {
      allClientsCall({});
    }
  }, [allClientsCall, ctx.isLoggedAdmin]);

  return (
    <React.Fragment>
      <AppBarCustom
        isDetail={true}
        title="Nová žádost"
        breadCrumb={newRequestBreadCrumb}
        addOrRemoveButtonCategory="request"
        pathToAdd={""}
        isSearchFunctionActive={false}
      />
      <Box sx={styles.stepperBox}>
        <Stepper activeStep={activeStep} alternativeLabel sx={styles.stepper}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
      {activeStep == 0 && (
        <Box>
          <Typography variant="h5">1. Termín</Typography>
          <Typography variant="body2" sx={styles.description}>
            Pro vytvoření nové žádosti, nejdříve vyberte termín rezervace.
          </Typography>
          <Box sx={styles.datePicker}>
            <DatePickerComp
              dateFrom={nextHourTime}
              dateTo={nextHourTime.add(2, "hours")}
              selectedDateInterval={dateTimeIntervalHandler}
              readonly={false}
            />
          </Box>
          {isDateTimeSet && (
            <Box>
              <Typography variant="h5">2. Výběr místnosti</Typography>
              <RoomTabs
                isDetail={false}
                isSelected={onRoomSelectedHandler}
                dateTimeInterval={dateTimeInterval}
                since={dateTimeInterval[0]}
              />
            </Box>
          )}
        </Box>
      )}
      {activeStep == 1 && (
        <Box sx={styles.blockPartTwo}>
          <Typography variant="body2" sx={styles.description}>
            Termín
          </Typography>
          <DatePickerComp
            dateFrom={dateTimeInterval[0]}
            dateTo={dateTimeInterval[1]}
            selectedDateInterval={dateTimeIntervalHandler}
            readonly={true}
          />
          <TextField
            label="Místnost"
            id="roomName"
            value={selectedRoom.name}
            inputProps={{ readOnly: true }}
            sx={styles.formItem}
          />
          <Divider sx={styles.formItem} />
          <FormContainer
            defaultValues={{
              eventName: newRequest.name,
              peopleCount: newRequest.occupancy,
              refreshmentCheckbox: newRequest.replenishment,
              eventDescripton: newRequest.description,
              companyId: newRequest.clientId,
            }}
            onSuccess={submitHandler}
          >
            {ctx.isLoggedAdmin && (
              <React.Fragment>
                <SelectElement
                  name="companyId"
                  validation={required}
                  label="Název klienta"
                  sx={styles.formItem}
                  options={allClients}
                  onChange={onChangeClientHandler}
                />
                <SelectElement
                  name="userId"
                  validation={required}
                  label="Uživatelský účet"
                  sx={styles.formItem}
                  options={allUsers}
                />
              </React.Fragment>
            )}
            <TextFieldElement
              name="eventName"
              validation={{ ...required, ...maxRequestNameLenght }}
              label="Název události"
              sx={styles.formItem}
            />
            <TextFieldElement
              name="peopleCount"
              type="number"
              validation={{
                ...required,
                validate: {
                  isInt: isInteger,
                  maxCap: maxCapacity,
                },
              }}
              label="Počet lidí"
              placeholder={"Max. " + selectedRoom.capacity.toString() + "."}
              sx={styles.formItem}
              helperText="Maximální přibližný počet očekávaných hostí události"
            />
            <Typography sx={styles.formItem}>
              Občerstvení z restaurace
            </Typography>
            <CheckboxElement
              name="refreshmentCheckbox"
              label="Mám zájem, aby mě restaurace kontaktovala"
              helperText="Káva a voda jsou zdarma v rámci coworku"
              sx={styles.refreshmentCheckbox}
            />
            <TextFieldElement
              name="eventDescripton"
              label="Popis události (volitelné)"
              sx={styles.formItem}
            />
            <Stack direction="row">
              <Button
                variant="text"
                sx={styles.button}
                onClick={backButtonHandler}
              >
                Zpět
              </Button>
              <div className="space"></div>
              <Button type="submit" variant="contained" sx={styles.button}>
                Pokračovat
              </Button>
            </Stack>
          </FormContainer>
        </Box>
      )}
      {activeStep == 2 && (
        <Dialog open={open} onClose={handleClose}>
          {!ctx.isLoggedAdmin && (
            <DialogTitle>Závazné potvrzení žádosti</DialogTitle>
          )}
          {ctx.isLoggedAdmin && (
            <DialogTitle>Závazné potvrzení rezervace</DialogTitle>
          )}
          <DialogContent>
            {!ctx.isLoggedAdmin && (
              <Typography component="span">
                Závazně potvrzuji žádost{" "}
              </Typography>
            )}
            {ctx.isLoggedAdmin && (
              <Typography component="span">
                Závazně potvrzuji rezervaci události{" "}
              </Typography>
            )}
            <Typography component="span" sx={styles.modalTextBold}>
              {newRequest.name}
            </Typography>
            <Typography component="span"> v místnosti </Typography>
            <Typography component="span" sx={styles.modalTextBold}>
              {selectedRoom.name}
            </Typography>
            <Typography component="span"> v termínu </Typography>
            <Typography component="span" sx={styles.modalTextBold}>
              {dayjs(newRequest.since).format("DD.MM.YYYY HH:mm")} -{" "}
              {dayjs(newRequest.till).format("DD.MM.YYYY HH:mm")}.
            </Typography>
            {newRequest.replenishment && !ctx.isLoggedAdmin && (
              <Box sx={styles.modalRefreshment}>
                <Typography component="span">
                  Potvrzuji, že mám zájem, aby mě{" "}
                </Typography>
                <Typography component="span" sx={styles.modalTextBold}>
                  kontaktovala restaurace{" "}
                </Typography>
                <Typography component="span">ohledně občerstvení.</Typography>
              </Box>
            )}
          </DialogContent>
          <DialogActions>
            <Stack direction="row">
              <div className="space"></div>
              <Button onClick={handleClose} variant="text" color="error">
                Zpět
              </Button>
              <Link to="/requests" style={{ textDecoration: "none" }}>
                <AsyncButton
                  variant="text"
                  onClick={addNewRequestHandler}
                  state={newRequestState}
                >
                  Potvrdit
                </AsyncButton>
              </Link>
            </Stack>
          </DialogActions>
        </Dialog>
      )}
    </React.Fragment>
  );
};

export default NewRequest;
