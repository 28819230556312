import { Add, AddRounded, CancelRounded } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import { DataGrid, GridColDef, GridRowParams } from "@mui/x-data-grid";
import dayjs from "dayjs";
import React, { useContext, useEffect, useMemo, useState } from "react";

import { useApiClient } from "../../api/useApiClient";
import { useSafeAsync } from "../../hooks/useSafeAsync";
import { defineStyles } from "../../styles/defineStyles";
import AlertContext from "../contexts/AlertContext";
import AuthContext from "../contexts/AuthContext";
import UiContext from "../contexts/UiContext";
import { AsyncButton } from "../form/AsyncButton";
import { useRequiredValidation } from "../models/settings";
import AppBarCustom, { BreadCrumbsItem } from "../ui/AppBarCustom";
import CustomDialog from "../ui/CustomDialog";

const AdminSettings: React.FC = () => {
  const styles = defineStyles({
    main: {
      mt: 9,
      ml: "calc((100vw - 788px)/2)",
      alignItem: "center",
      width: "500px",
    },
    formItem: {
      width: "100%",
      mt: 3,
    },
    table: {
      mt: 2,
      minHeight: "640px",
    },
    tableBox: {
      mt: 3,
      width: "100%",
    },
    mainColumn: {
      fontWeight: "bold",
      width: "80%",
    },
    actionColumn: {
      fontWeight: "bold",
      width: "10%",
    },
    iconButton: {
      mr: 1,
    },
    comment: {
      mt: 2,
      width: "396px",
    },
    buttons: {
      mt: 2,
    },
  });

  const adminSettingsBreadCrumb: BreadCrumbsItem[] = [
    { name: "Nastavení", path: "/settings" },
  ];

  const { refresh } = useContext(UiContext);
  const required = useRequiredValidation();
  const api = useApiClient();
  const ctx = useContext(AuthContext);
  const { showSuccess } = useContext(AlertContext);

  const [pageSize, setPageSize] = useState(10);

  const [showDialog, setShowDialog] = useState(false);
  const [deleteAdminId, setDeleteAdminId] = useState<number>(null);
  const [allAdminsCall, allAdminsState] = useSafeAsync(api.findUserByParams);
  const [deleteAdminCall, deleteAdminState] = useSafeAsync(api.deleteUser);
  const [restoreAdminCall, restoreAdminState] = useSafeAsync(api.restoreUser);

  const isDeleted = (id: number) => {
    if (allAdminsState.value == null) return false;
    for (let i = 0; i < allAdminsState.value.data.length; i++) {
      if (id === allAdminsState.value.data[i].id) {
        if (allAdminsState.value.data[i].dateDeleted) {
          return true;
        } else {
          return false;
        }
      }
    }
    return false;
  };

  const adminColumns: GridColDef[] = [
    //{ field: "id", headerName: "id", flex: 1 },
    {
      field: "adminName",
      headerName: "Uživatel",
      flex: 1,
    },
    {
      field: "deleted",
      headerName: "Odstraněn dne",
      flex: 0.3,
    },
    {
      field: "action",
      headerName: "Akce",
      flex: 0.2,
      renderCell: ({ row }: Partial<GridRowParams>) => renderButton(row.id),
    },
  ];

  const renderButton = (params: number) => {
    if (allAdminsState.value == null) return <></>;
    if (allAdminsState.value.data.length > 1) {
      return (
        <React.Fragment>
          {ctx.loggedUser.userDTO.id !== params && !isDeleted(params) && (
            <AsyncButton
              color="error"
              variant="contained"
              state={deleteAdminState}
              onClick={() => deleteAdminDialogHandler(params)}
            >
              <CancelRounded sx={styles.iconButton} />
              Odebrat
            </AsyncButton>
          )}
          {ctx.loggedUser.userDTO.id !== params && isDeleted(params) && (
            <AsyncButton
              color="success"
              variant="contained"
              state={restoreAdminState}
              onClick={() => restoreAdminHandler(params)}
            >
              <AddRounded sx={styles.iconButton} />
              Obnovit
            </AsyncButton>
          )}
        </React.Fragment>
      );
    }
    return <></>;
  };

  const deleteAdminDialogHandler = (id: number) => {
    //ulozime id mazaneho admina a otevreme dialog pro potvrzeni
    setDeleteAdminId(id);
    setShowDialog(true);
  };

  const deleteAdminHandler = async () => {
    if (deleteAdminId == null) {
      return;
    }
    const result = await deleteAdminCall({ userId: deleteAdminId });
    if (typeof result === "boolean" && result) {
      setShowDialog(false);
      refresh();
    }
  };

  const restoreAdminHandler = async (id: number) => {
    // obnovime uzivatelsky ucet
    const result = await restoreAdminCall({ userId: id });
    if (typeof result === "boolean" && result) {
      showSuccess("Administrátor byl obnoven.", "");
      setTimeout(() => {
        refresh();
      }, 250);
    }
  };

  const adminRows = useMemo(() => {
    if (allAdminsState.value == null) {
      return [];
    }
    return allAdminsState.value.data.map((admin) => {
      return {
        id: admin.id,
        adminName: admin.email,
        deleted: admin.dateDeleted
          ? dayjs(admin.dateDeleted).format("DD.MM.YYYY HH:mm")
          : "-",
      };
    });
  }, [allAdminsState.value]);

  const onCloseDialogHandler = () => {
    setShowDialog(false);
  };

  useEffect(() => {
    allAdminsCall({ role: "ADMIN", activeOnly: false });
  }, [allAdminsCall]);

  return (
    <React.Fragment>
      <AppBarCustom
        isDetail={false}
        title="Nastavení"
        breadCrumb={adminSettingsBreadCrumb}
        pathToAdd="/settings/new"
        isSearchFunctionActive={false}
        addOrRemoveButtonCategory="settings.admin"
      />
      <Box sx={styles.tableBox}>
        <Typography variant="h5">Správci systému</Typography>
        <DataGrid
          rows={adminRows}
          columns={adminColumns}
          sx={styles.table}
          rowsPerPageOptions={[10, 25, 50]}
          pageSize={pageSize}
          onPageSizeChange={(newPage) => setPageSize(newPage)}
        />
      </Box>
      {showDialog && (
        <CustomDialog
          openDialog={showDialog}
          closed={onCloseDialogHandler}
          title="Odstranit administrátora"
          subtitle="Po potvrzení bude odstraněn tento administrátor."
          commentLabel=""
          commentPlaceholder=""
          buttonText="Odstranit administrátora"
          onButtonClick={deleteAdminHandler}
        />
      )}
    </React.Fragment>
  );
};

export default AdminSettings;
