import React, { useCallback, useEffect, useState } from "react";

import { LoggedUserDTO } from "../../api/types";
import { buildApiClient } from "../../api/buildApiClient";

/* type AuthContextType = {
  isLoggedIn: Boolean;
  onLogout: () => {};
  onLogin: (email: String, password: String) => {};
}; */

const AuthContext = React.createContext({
  loggedUser: null as LoggedUserDTO | null,
  isLoggedIn: false,
  isLoggedAdmin: false,
  doLogout: () => {
    //donothing
  },
  setLoggedUser: (user: LoggedUserDTO) => {
    //donothing
  },
});

export const AuthContextProvider: React.FC<{
  children?: React.ReactNode;
}> = (props) => {
  const [loggedUser, _setLoggedUser] = useState<LoggedUserDTO | null>(null);

  // "Autologin"
  useEffect(() => {
    const token = localStorage.getItem("fnxMicronautToken");
    if (token) {
      console.log("Found token in local storage");
      // Tady si chyby osetruju sam a nepouzivam useSafeAsyncCall
      // protoze je nikde zobrazovat nechci, ale to je jedna z mala vyjimek.
      // s api se tu taky pracuje zvlastne
      const api = buildApiClient(process.env.REACT_APP_SERVER_ROOT, token);
      api
        .getLogged({})
        .then((user) => {
          console.log("Logged user", user);
          _setLoggedUser(user);
        })
        .catch((err) => {
          console.log(err);
          _setLoggedUser(null);
        });
    } else {
      console.log("No token in local storage");
      _setLoggedUser(null);
    }
  }, []);

  const setLoggedUser = useCallback((incommingUser: LoggedUserDTO) => {
    _setLoggedUser(incommingUser);
    if (incommingUser?.authToken) {
      console.log("Saving token to local storage");
      localStorage.setItem("fnxMicronautToken", incommingUser!.authToken);
    } else {
      console.log("Removing token from local storage");
      localStorage.removeItem("fnxMicronautToken");
    }
  }, []);

  const logoutHandler = () => {
    setLoggedUser(null);
  };

  return (
    <AuthContext.Provider
      value={{
        loggedUser,
        isLoggedIn: loggedUser != null,
        isLoggedAdmin: loggedUser?.userDTO?.role === "ADMIN",
        doLogout: logoutHandler,
        setLoggedUser: setLoggedUser,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
