import { Chip, Typography } from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridEventListener,
  GridRowParams,
} from "@mui/x-data-grid";
import dayjs from "dayjs";
import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { RequestDTO } from "../../api/types";
import { defineStyles } from "../../styles/defineStyles";

const EventTable: React.FC<{
  events: RequestDTO[];
}> = (props) => {
  const styles = defineStyles({
    table: {
      mt: 3,
      minHeight: "640px",
    },
    head: {
      fontWeight: "bold",
    },
  });

  const navigate = useNavigate();

  const [pageSize, setPageSize] = useState(10);

  const eventsColumns: GridColDef[] = [
    { field: "requestName", headerName: "Název žádosti", flex: 1 },
    { field: "description", headerName: "Popis", flex: 1 },
    { field: "dateTimeFromTo", headerName: "Termín", flex: 1.2 },
    { field: "roomName", headerName: "Místnost", flex: 1 },
    { field: "clientName", headerName: "Klient", flex: 1 },
    { field: "peopleCount", headerName: "Počet lidí", flex: 0.5 },
    {
      field: "requestStatus",
      headerName: "Status",
      flex: 1.5,
      renderCell: ({ row }: Partial<GridRowParams>) => acceptedChip,
    },
  ];

  const eventsRows = useMemo(() => {
    return props.events.map((event) => {
      return {
        id: event.id,
        requestName: event.name,
        description: event.description,
        dateTimeFromTo:
          dayjs(event.since).format("DD.MM.YYYY HH:mm") +
          " - " +
          dayjs(event.till).format("DD.MM.YYYY HH:mm"),
        roomName: event.roomDTO.name,
        clientName: event.clientDTO.name,
        peopleCount: event.occupancy,
        status: event.status,
      };
    });
  }, [props.events]);

  const eventDetailHandler: GridEventListener<"rowClick"> = (params) => {
    const path = "/events/" + params.id;
    navigate(path);
  };

  const acceptedChip = (
    <Chip variant="filled" color="success" label="Potvrzená" />
  );

  return (
    <React.Fragment>
      {props.events.length == 0 && (
        <Typography sx={{ mt: 2 }}>Nenalezli jsme žádnou událost</Typography>
      )}
      {props.events.length > 0 && (
        <DataGrid
          rows={eventsRows}
          columns={eventsColumns}
          sx={styles.table}
          onRowDoubleClick={eventDetailHandler}
          rowsPerPageOptions={[10, 25, 50]}
          pageSize={pageSize}
          onPageSizeChange={(newPage) => setPageSize(newPage)}
        />
      )}
    </React.Fragment>
  );
};

export default EventTable;
