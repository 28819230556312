import React, { useContext } from "react";

import AuthContext from "../contexts/AuthContext";

import AdminSettings from "./AdminSettings";
import UserSettings from "./UserSettings";

export const AppSettings: React.FC = () => {
  const ctx = useContext(AuthContext);

  return (
    <React.Fragment>
      {!ctx.isLoggedAdmin && <UserSettings />}
      {ctx.isLoggedAdmin && <AdminSettings />}
    </React.Fragment>
  );
};
