import { Check, Close } from "@mui/icons-material";
import { Box, Button, Chip, Typography } from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridEventListener,
  GridRowParams,
} from "@mui/x-data-grid";
import dayjs from "dayjs";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { RequestDTO } from "../../api/types";
import { useApiClient } from "../../api/useApiClient";
import { useSafeAsync } from "../../hooks/useSafeAsync";
import { defineStyles } from "../../styles/defineStyles";
import AuthContext from "../contexts/AuthContext";
import UiContext from "../contexts/UiContext";
import CustomDialog from "../ui/CustomDialog";

const RequestTable: React.FC<{
  requests: RequestDTO[];
  refreshReq: () => void;
}> = (props) => {
  const styles = defineStyles({
    tabs: {
      mt: 3,
    },
    table: {
      mt: 3,
      minHeight: "640px",
    },
    head: {
      fontWeight: "bold",
    },
    icon: {
      mr: 1,
      width: "16px",
      height: "18px",
    },
    button: {
      alignItems: "center",
    },
    buttonText: {
      fontSize: "13px",
    },
  });

  const ctx = useContext(AuthContext);
  const { refresh } = useContext(UiContext);
  const navigate = useNavigate();
  const api = useApiClient();

  const [pageSize, setPageSize] = useState(10);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [deneidRequestId, setDeniedRequestId] = useState<number | null>(null);
  const [updateRequestStatusCall, updateRequestStatusState] = useSafeAsync(
    api.updateRequestStatus
  );

  const requestsColumns: GridColDef[] = [
    { field: "requestName", headerName: "Název žádosti", flex: 1 },
    { field: "description", headerName: "Popis", flex: 1.2 },
    { field: "dateTimeFromTo", headerName: "Termín", flex: 1.2 },
    { field: "roomName", headerName: "Místnost", flex: 1 },
    { field: "clientName", headerName: "Klient", flex: 1 },
    { field: "peopleCount", headerName: "Počet lidí", flex: 0.5 },
    {
      field: "requestStatus",
      headerName: "Status",
      flex: 1.5,
      renderCell: ({ row }: Partial<GridRowParams>) =>
        getRightStatusChip(row.id, row.status),
    },
  ];

  const requestsRows = useMemo(() => {
    return props.requests.map((request) => {
      return {
        id: request.id,
        requestName: request.name,
        description: request.description,
        dateTimeFromTo:
          dayjs(request.since).format("DD.MM.YYYY HH:mm") +
          " - " +
          dayjs(request.till).format("DD.MM.YYYY HH:mm"),
        roomName: request.roomDTO.name,
        clientName: request.clientDTO.name,
        peopleCount: request.occupancy,
        status: request.status,
      };
    });
  }, [props.requests]);

  const requestDetailHandler: GridEventListener<"rowClick"> = (params) => {
    // nakonec povolime detail i uzivatelum
    //if (!ctx.isLoggedAdmin) return;
    const path = "/requests/" + params.id;
    navigate(path);
  };

  const acceptedChip = (
    <Chip variant="filled" color="success" label="Potvrzená" />
  );
  const rejectedChip = (
    <Chip variant="filled" color="error" label="Zamítnutá" />
  );
  const undecidedChip = (
    <Chip variant="filled" color="primary" label="Čekání na potvrzení" />
  );

  const approveHandler = useCallback(
    async (id: number) => {
      // posilame zmenu statusu serveru
      const result = await updateRequestStatusCall({
        id: id,
        requestId: id,
        status: "ACCEPTED",
        force: false,
      });
      if (result != null) {
        refresh();
      }
    },
    [updateRequestStatusCall, refresh]
  );

  const declineHandler = (id: number) => {
    setShowDialog(true);
    setDeniedRequestId(id);
  };

  const closeDialogHandler = () => {
    // zavreni dialogu(schovani)
    setShowDialog(false);
    setDeniedRequestId(0);
  };

  const requestDeniedHandler = useCallback(
    async (comment: string) => {
      // akce po stisknuti tlacitka v dialogu
      setShowDialog(false);

      // nastaveni zadosti jako zamitnuta

      const result = await updateRequestStatusCall({
        id: deneidRequestId,
        requestId: deneidRequestId,
        status: "DECLINED",
        declineComment: comment,
        force: false,
      });
      if (result != null) {
        refresh();
      }
    },
    [setShowDialog, updateRequestStatusCall, refresh, deneidRequestId]
  );

  const undecidedAdminComponent = (id: string) => {
    return (
      <Box>
        <Button
          variant="contained"
          color="success"
          sx={styles.button}
          size="small"
          onClick={() => approveHandler(parseInt(id))}
        >
          <Check sx={styles.icon} />
          <Typography sx={styles.buttonText}>Potvrdit</Typography>
        </Button>

        <Button
          variant="contained"
          color="error"
          sx={{ ml: 1 }}
          size="small"
          onClick={() => declineHandler(parseInt(id))}
        >
          <Close sx={styles.icon} />
          <Typography sx={styles.buttonText}>Zamítnout</Typography>
        </Button>
      </Box>
    );
  };

  const getRightStatusChip = (id: string, status: string) => {
    if (ctx.isLoggedAdmin) {
      if (status === "REQUEST") return undecidedAdminComponent(id);
      if (status === "ACCEPTED") return acceptedChip;
      if (status === "DECLINED") return rejectedChip;
    } else {
      if (status === "REQUEST") return undecidedChip;
      if (status === "ACCEPTED") return acceptedChip;
      if (status === "DECLINED") return rejectedChip;
    }
    return <></>;
  };

  return (
    <React.Fragment>
      {props.requests.length === 0 && (
        <Typography sx={{ mt: 2 }}>Nenalezli jsme žádnou žádost</Typography>
      )}
      {props.requests.length > 0 && (
        <DataGrid
          rows={requestsRows}
          columns={requestsColumns}
          sx={styles.table}
          onRowDoubleClick={requestDetailHandler}
          rowsPerPageOptions={[10, 25, 50]}
          pageSize={pageSize}
          onPageSizeChange={(newPage) => setPageSize(newPage)}
        />
      )}
      {showDialog && (
        <CustomDialog
          openDialog={showDialog}
          closed={closeDialogHandler}
          title="Zamítnutí žádosti"
          subtitle="Předtím než definitivně žádost zamítnete, udejte prosím důvod. Zvýšíte tak efektivitu komunikace s klientem."
          commentLabel="Důvod zamítnutí žádosti"
          commentPlaceholder="Nevhodný termín, zkuste prosím jiný."
          buttonText="Zamítnout žádost"
          onButtonClick={requestDeniedHandler}
        />
      )}{" "}
    </React.Fragment>
  );
};

export default RequestTable;
