import { Box, Button, Stack, Typography } from "@mui/material";
import React, { useContext } from "react";
import {
  FormContainer,
  PasswordElement,
  TextFieldElement,
} from "react-hook-form-mui";

import { useApiClient } from "../../api/useApiClient";
import { useSafeAsync } from "../../hooks/useSafeAsync";
import { defineStyles } from "../../styles/defineStyles";
import AlertContext from "../contexts/AlertContext";
import AuthContext from "../contexts/AuthContext";
import UiContext from "../contexts/UiContext";
import { AsyncButton } from "../form/AsyncButton";
import { useMaxLengthValidation } from "../form/validations/useMaxLengthValidation";
import { useMinLengthValidation } from "../form/validations/useMinLengthValidation";
import { usePhoneNumberValidation } from "../form/validations/usePhoneNumberValidation";
import { useRequiredValidation } from "../models/settings";
import AppBarCustom, { BreadCrumbsItem } from "../ui/AppBarCustom";

const UserSettings: React.FC = () => {
  const styles = defineStyles({
    main: {
      mt: 9,
      ml: "calc((100vw - 788px)/2)",
      alignItem: "center",
      width: "500px",
    },
    formItem: {
      width: "100%",
      mt: 3,
    },
  });

  const buttonText = "Uložit změny";

  const userSettingsBreadCrumb: BreadCrumbsItem[] = [
    { name: "Nastavení", path: "/settings" },
  ];

  const ctx = useContext(AuthContext);
  const { refresh } = useContext(UiContext);
  const { showSuccess } = useContext(AlertContext);

  const api = useApiClient();

  const [updateUserCall, updateUserState] = useSafeAsync(api.updateUser);
  const [updateUserPasswordCall, updateUserPasswordState] = useSafeAsync(
    api.updateUserPassword
  );

  const required = useRequiredValidation();
  const minPasswordLength = useMinLengthValidation(8);
  const maxPasswordLength = useMaxLengthValidation(128);
  const phoneValidation = usePhoneNumberValidation();

  const changeUserPhoneHandler = async (data: { userPhone: string }) => {
    const result = await updateUserCall({ phone: data.userPhone });
    if (typeof result === "object" && result) {
      showSuccess("Telefonní číslo bylo změněno", "");
    }
  };

  const changePasswordHandler = async (data: {
    oldPassword: string;
    newPassword: string;
  }) => {
    const result = await updateUserPasswordCall({
      id: ctx.loggedUser.userDTO.id,
      userId: ctx.loggedUser.userDTO.id,
      oldPassword: data.oldPassword,
      newPassword: data.newPassword,
    });
    if (typeof result === "object" && result) {
      showSuccess("Heslo bylo změněno", "");
    }
    setTimeout(() => refresh(), 3000);
  };

  return (
    <React.Fragment>
      <AppBarCustom
        isDetail={false}
        title="Nastavení účtu"
        breadCrumb={userSettingsBreadCrumb}
        pathToAdd={""}
        addOrRemoveButtonCategory="settings"
        isSearchFunctionActive={false}
      />
      <Box sx={styles.main}>
        <FormContainer
          defaultValues={{
            userPhone: ctx.loggedUser.userDTO.phone
              ? ctx.loggedUser.userDTO.phone
              : "-",
          }}
          onSuccess={changeUserPhoneHandler}
        >
          <Typography variant="h5">Základní informace</Typography>
          <TextFieldElement
            name="userPhone"
            id="userPhone"
            label="Telefon"
            validation={{
              ...required,
              ...phoneValidation,
            }}
            sx={styles.formItem}
          />
          <Stack direction="row" sx={styles.formItem}>
            <div className="space"></div>
            <AsyncButton variant="contained" state={updateUserState}>
              {buttonText}
            </AsyncButton>
          </Stack>
        </FormContainer>
        <Typography variant="h5" sx={styles.formItem}>
          Změnit heslo
        </Typography>
        <Typography variant="body2" sx={styles.formItem}>
          Vytvořte unikátní heslo, které nelze prakticky uhodnout.
        </Typography>
        <FormContainer defaultValues={{}} onSuccess={changePasswordHandler}>
          <PasswordElement
            name="oldPassword"
            label="Původní heslo"
            validation={required}
            sx={styles.formItem}
          />
          <PasswordElement
            name="newPassword"
            label="Nové heslo"
            validation={{
              ...required,
              ...minPasswordLength,
              ...maxPasswordLength,
            }}
            sx={styles.formItem}
          />
          <Stack direction="row" sx={styles.formItem}>
            <div className="space"></div>
            <AsyncButton variant="contained" state={updateUserPasswordState}>
              {buttonText}
            </AsyncButton>
          </Stack>
        </FormContainer>
      </Box>
    </React.Fragment>
  );
};

export default UserSettings;
