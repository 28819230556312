import { AddRounded } from "@mui/icons-material";
import { Typography } from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridEventListener,
  GridRowParams,
} from "@mui/x-data-grid";
import dayjs from "dayjs";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useApiClient } from "../../api/useApiClient";
import { useSafeAsync } from "../../hooks/useSafeAsync";
import { defineStyles } from "../../styles/defineStyles";
import AlertContext from "../contexts/AlertContext";
import UiContext from "../contexts/UiContext";
import { AsyncButton } from "../form/AsyncButton";
import { LoadingGuard } from "../form/LoadingGuard";

const UserTable: React.FC = () => {
  const styles = defineStyles({
    table: {
      mt: 3,
      minHeight: "640px",
    },
    head: {
      fontWeight: "bold",
      width: "100%",
    },
    headName: {
      fontWeight: "bold",
      width: "20%",
    },
    headDescription: {
      fontWeight: "bold",
      width: "20%",
    },
    headItem: {
      fontWeight: "bold",
      width: "10%",
    },
    iconButton: {
      mr: 1,
    },
  });

  const data = useParams();
  const clientId = parseInt(data.id);
  const navigate = useNavigate();
  const { showSuccess } = useContext(AlertContext);
  const { refresh } = useContext(UiContext);

  const api = useApiClient();

  const [pageSize, setPageSize] = useState(10);

  const [allUsersForClientCall, allUsersForClientState] = useSafeAsync(
    api.findAllUsersByClientId
  );
  const [clientForUserCall, clientForUserState] = useSafeAsync(
    api.findClientById
  );
  const [restoreUserCall, restoreUserState] = useSafeAsync(api.restoreUser);

  const usersColumns: GridColDef[] = [
    { field: "accountName", headerName: "Název účtu", flex: 1 },
    { field: "userEmail", headerName: "Kontakt", flex: 1 },
    { field: "clientDescription", headerName: "Status", flex: 1 },
    { field: "eventsCount", headerName: "Počet událostí", flex: 0.5 },
    { field: "requestCount", headerName: "Počet žádostí", flex: 0.5 },
    { field: "isDeleted", headerName: "Odstraněn dne", flex: 0.6 },
    {
      field: "actions",
      headerName: "Akce",
      flex: 0.7,
      renderCell: ({ row }: Partial<GridRowParams>) => renderButton(row.id),
    },
  ];

  const isDeleted = (id: number) => {
    if (allUsersForClientState.value == null) return false;
    for (let i = 0; i < allUsersForClientState.value.data.length; i++) {
      if (id === allUsersForClientState.value.data[i].id) {
        if (allUsersForClientState.value.data[i].dateDeleted) {
          return true;
        } else {
          return false;
        }
      }
    }
    return false;
  };

  const restoreUserHandler = async (id: number) => {
    const result = await restoreUserCall({ userId: id });
    if (typeof result === "boolean" && result) {
      showSuccess("Uživatel byl obnoven.", "");
      setTimeout(() => {
        refresh();
      }, 250);
    }
  };

  const renderButton = (id: number) => {
    if (allUsersForClientState.value == null) return <></>;
    return (
      <React.Fragment>
        {isDeleted(id) && (
          <AsyncButton
            color="success"
            variant="contained"
            state={restoreUserState}
            onClick={() => restoreUserHandler(id)}
          >
            <AddRounded sx={styles.iconButton} />
            Obnovit
          </AsyncButton>
        )}
      </React.Fragment>
    );
  };

  const userClient = useMemo(() => {
    if (clientForUserState.value == null) {
      return {};
    }
    return {
      description: clientForUserState.value.description,
      eventCount: clientForUserState.value.eventsCount,
      requestCount: clientForUserState.value.requestsCount,
    };
  }, [clientForUserState.value]);

  const usersRows = useMemo(() => {
    if (allUsersForClientState.value == null) {
      return [];
    }
    return allUsersForClientState.value.data.map((user) => {
      return {
        id: user.id,
        accountName: user.email,
        userEmail: user.email,
        clientDescription: userClient.description,
        eventsCount: user.eventsCount,
        requestCount: user.requestsCount,
        isDeleted: user.dateDeleted
          ? dayjs(user.dateDeleted).format("DD.MM.YYYY HH:mm")
          : "-",
      };
    });
  }, [allUsersForClientState.value, userClient.description]);

  const userDetailHandler: GridEventListener<"rowDoubleClick"> = (params) => {
    const path = "/clients/" + clientId + "/user/" + params.id;
    navigate(path);
  };

  useEffect(() => {
    allUsersForClientCall({ clientId: clientId, activeOnly: false });
    clientForUserCall({ id: clientId });
  }, [allUsersForClientCall, clientForUserCall, clientId]);

  return (
    <React.Fragment>
      <LoadingGuard state={allUsersForClientState}>
        <LoadingGuard state={clientForUserState}>
          {usersRows.length === 0 && (
            <Typography sx={{ mt: 2 }}>
              Nenalezli jsme žádné úživatele
            </Typography>
          )}
          {usersRows.length > 0 && (
            <DataGrid
              rows={usersRows}
              columns={usersColumns}
              sx={styles.table}
              onRowDoubleClick={userDetailHandler}
              rowsPerPageOptions={[10, 25, 50]}
              pageSize={pageSize}
              onPageSizeChange={(newPage) => setPageSize(newPage)}
            />
          )}
        </LoadingGuard>
      </LoadingGuard>
    </React.Fragment>
  );
};

export default UserTable;
