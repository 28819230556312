import { Box } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";

import AppBarCustom, { BreadCrumbsItem } from "../ui/AppBarCustom";
import { defineStyles } from "../../styles/defineStyles";
import AuthContext from "../contexts/AuthContext";
import Unavailable from "../ui/Unavailable";
import { useSafeAsync } from "../../hooks/useSafeAsync";
import { useApiClient } from "../../api/useApiClient";
import { LoadingGuard } from "../form/LoadingGuard";

import RoomDetailItem from "./RoomDetailItem";

const RoomDetail: React.FC = () => {
  const styles = defineStyles({
    main: {
      display: "flex",
      flexDirection: "column",
    },
  });

  const data = useParams();
  const roomId = parseInt(data.id);
  const api = useApiClient();

  const ctx = useContext(AuthContext);
  const [currentRoomCall, currentRoomState] = useSafeAsync(api.findRoomById);

  const roomDetailBreadCrumb: BreadCrumbsItem[] = [
    { name: "Místnosti", path: "/rooms" },
    { name: "Detail místnosti", path: "" },
  ];

  useEffect(() => {
    currentRoomCall({ id: roomId });
  }, [currentRoomCall, roomId]);

  return (
    <LoadingGuard state={currentRoomState}>
      {ctx.isLoggedAdmin && currentRoomState.value && (
        <Box sx={styles.main}>
          <AppBarCustom
            isDetail={true}
            title={currentRoomState.value.name}
            breadCrumb={roomDetailBreadCrumb}
            addOrRemoveButtonCategory="room"
            pathToAdd={""}
            isSearchFunctionActive={false}
          />
          <RoomDetailItem room={currentRoomState.value} />
        </Box>
      )}
      {!ctx.isLoggedAdmin && <Unavailable />}
    </LoadingGuard>
  );
};

export default RoomDetail;
