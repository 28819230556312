import { Box, TextField, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useApiClient } from "../../api/useApiClient";
import { useSafeAsync } from "../../hooks/useSafeAsync";
import { defineStyles } from "../../styles/defineStyles";
import UiContext from "../contexts/UiContext";
import { LoadingGuard } from "../form/LoadingGuard";
import AppBarCustom, { BreadCrumbsItem } from "../ui/AppBarCustom";
import CustomDialog from "../ui/CustomDialog";

const UserDetail: React.FC = () => {
  const styles = defineStyles({
    main: {
      mt: 3,
      width: "500px",
    },
    formItem: {
      width: "100%",
      mt: 3,
    },
  });
  const api = useApiClient();
  const data = useParams();
  const userId = parseInt(data.id);
  const clientId = parseInt(data.clientId);
  const { refresh } = useContext(UiContext);
  const navigate = useNavigate();
  const path = "/clients/" + clientId.toString();

  const [userClientCall, userClientState] = useSafeAsync(api.findClientById);
  const [userCall, userState] = useSafeAsync(api.findUserById);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [deleteUserCall, deleteUserState] = useSafeAsync(api.deleteUser);

  const userDetailBreadcrumb: BreadCrumbsItem[] = [
    { name: "Klienti", path: "/clients" },
    { name: "Detail", path: "" },
  ];

  const onRemoveFuncitonHandler = () => {
    setShowDialog(true);
  };

  const closeHandler = () => {
    setShowDialog(false);
  };

  const removeUserHandler = async () => {
    setShowDialog(false);
    // remove user, maybe move of request and events to another user in company
    await deleteUserCall({ userId: userId });
    refresh();
    navigate(path);
  };

  useEffect(() => {
    userCall({ id: userId });
    userClientCall({ id: clientId });
  }, [clientId, userCall, userClientCall, userId]);

  return (
    <React.Fragment>
      <AppBarCustom
        isDetail={true}
        title="Uživatel detail"
        breadCrumb={userDetailBreadcrumb}
        addOrRemoveButtonCategory="user"
        pathToAdd=""
        isSearchFunctionActive={false}
        onRemoveFunction={onRemoveFuncitonHandler}
      />
      <LoadingGuard state={userState}>
        <LoadingGuard state={userClientState}>
          <Box sx={styles.main}>
            <Typography variant="h5">Základní informace</Typography>
            {userClientState.value && (
              <TextField
                id="companyName"
                label="Název společnosti"
                value={userClientState.value.name}
                inputProps={{ readOnly: true }}
                sx={styles.formItem}
              />
            )}
            {userState.value && (
              <React.Fragment>
                <TextField
                  id="userPhone"
                  label="Telefon"
                  value={userState.value.phone ? userState.value.phone : "-"}
                  inputProps={{ readOnly: true }}
                  sx={styles.formItem}
                />
                <TextField
                  id="userEmail"
                  label="Email"
                  value={userState.value.email}
                  inputProps={{ readOnly: true }}
                  sx={styles.formItem}
                />
              </React.Fragment>
            )}
          </Box>
        </LoadingGuard>
      </LoadingGuard>
      {showDialog && (
        <CustomDialog
          openDialog={showDialog}
          closed={closeHandler}
          title="Odstranit uživatele"
          subtitle="Odstraněním uživatele nedojde ke smazání žádostí ani událostí. Bude odstraněn pouze tento uživatel."
          commentLabel=""
          commentPlaceholder=""
          buttonText="Odstranit uživatele"
          onButtonClick={removeUserHandler}
        />
      )}
    </React.Fragment>
  );
};

export default UserDetail;
