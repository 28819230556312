import { useTranslation } from "react-i18next";

export const useMaxCapacityValidation = (capacity: number) => {
  const { t } = useTranslation();

  return {
    validate: (v?: number) => {
      if (v > capacity)
        return t(
          "common.error.capacityOver",
          "Kapacita místnosti je omezena na {{max}} lidí",
          { max: capacity }
        );

      return undefined;
    },
  };
};
