import { Box, Button, Stack } from "@mui/material";
import React, { useCallback, useContext, useEffect, useMemo } from "react";
import {
  FormContainer,
  SelectElement,
  TextFieldElement,
} from "react-hook-form-mui";
import { useNavigate, useParams } from "react-router-dom";

import { useApiClient } from "../../api/useApiClient";
import { useSafeAsync } from "../../hooks/useSafeAsync";
import { defineStyles } from "../../styles/defineStyles";
import UiContext from "../contexts/UiContext";
import { AsyncButton } from "../form/AsyncButton";
import { LoadingGuard } from "../form/LoadingGuard";
import { useEmailValidation } from "../form/validations/useEmailValidation";
import { useRequiredValidation } from "../models/settings";
import AppBarCustom, { BreadCrumbsItem } from "../ui/AppBarCustom";

const NewUser: React.FC = () => {
  const styles = defineStyles({
    main: {
      mt: 9,
      ml: "calc((100vw - 788px)/2)",
      alignItem: "center",
      width: "500px",
    },
    formItem: {
      width: "100%",
      mt: 3,
    },
  });

  const newUserBreadcrumb: BreadCrumbsItem[] = [
    { name: "Klienti", path: "/clients" },
    { name: "Nový uživatel", path: "" },
  ];

  const required = useRequiredValidation();
  const emailValidation = useEmailValidation();

  const navigate = useNavigate();
  const urlData = useParams();
  const clientId = parseInt(urlData.clientId);
  const api = useApiClient();
  const { refresh } = useContext(UiContext);
  const [createUserCall, createUserState] = useSafeAsync(api.createUser);
  const [allCompaniesCall, allCompaniesState] = useSafeAsync(
    api.findAllClients
  );

  const addNewUserHandler = useCallback(
    async (data: { companyId: number; userEmail: string }) => {
      //add new user
      const result = await createUserCall({
        email: data.userEmail,
        clientId: data.companyId,
        role: "USER",
      });
      const path = "/clients/" + data.companyId;
      if (typeof result === "object" && result) {
        refresh();
        navigate(path);
      }
    },
    [createUserCall, refresh, navigate]
  );

  useEffect(() => {
    allCompaniesCall({});
  }, [allCompaniesCall]);

  /* const findDefaultClientOption = () => {
    if (allCompaniesState.value == null) {
      return {};
    }
    for (let i = 0; i < allCompaniesState.value.data.length; i++) {
      if (clientId === allCompaniesState.value.data[i].id) {
        return { id: clientId, label: allCompaniesState.value.data[i].name };
      }
    }
    return {};
  }; */

  const allCompaniesOptions = useMemo(() => {
    if (allCompaniesState.value == null) {
      return [];
    }
    return allCompaniesState.value.data.map((client) => {
      return { id: client.id, label: client.name };
    });
  }, [allCompaniesState.value]);

  return (
    <React.Fragment>
      <AppBarCustom
        isDetail={false}
        title="Nový uživatel"
        breadCrumb={newUserBreadcrumb}
        addOrRemoveButtonCategory="user"
        pathToAdd=""
        isSearchFunctionActive={false}
      />
      <Box sx={styles.main}>
        <LoadingGuard state={allCompaniesState}>
          <FormContainer
            defaultValues={{ companyId: clientId }}
            onSuccess={addNewUserHandler}
          >
            {allCompaniesState.value && (
              <SelectElement
                id="company"
                name="companyId"
                label="Klient"
                validation={required}
                options={allCompaniesOptions}
                sx={styles.formItem}
              />
            )}
            <TextFieldElement
              id="userEmail"
              name="userEmail"
              label="Email"
              validation={{ ...required, ...emailValidation }}
              sx={styles.formItem}
            />
            <Stack direction="row" sx={styles.formItem}>
              <div className="space"></div>
              <AsyncButton state={createUserState}>
                Vytvořit nový účet
              </AsyncButton>
            </Stack>
          </FormContainer>
        </LoadingGuard>
      </Box>
    </React.Fragment>
  );
};

export default NewUser;
