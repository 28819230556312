import { useTranslation } from "react-i18next";

export const useIsIntegerValidation = () => {
  const { t } = useTranslation();

  return {
    validate: (v?: string) => {
      if (Number.isInteger(parseFloat(v))) {
        return undefined;
      } else {
        return t("common.error.isNotInteger", "Můžete zadat pouze celé číslo");
      }
    },
  };
};
