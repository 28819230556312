import React, { useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Dayjs } from "dayjs";

import { RoomDTO } from "../../api/types";
import { useSafeAsync } from "../../hooks/useSafeAsync";
import { useApiClient } from "../../api/useApiClient";
import { LoadingGuard } from "../form/LoadingGuard";

import RoomList from "./RoomList";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`ab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}

const RoomTabs: React.FC<{
  isDetail: boolean;
  isSelected: (item: RoomDTO) => void;
  dateTimeInterval: Dayjs[];
  since?: Dayjs;
}> = (props) => {
  const [value, setValue] = React.useState(0);
  const api = useApiClient();
  const [allRoomsCall, allRoomsState] = useSafeAsync(
    api.findAllRoomsWithStatusInDateInterval
  );

  const findAvailableRooms = (rooms: RoomDTO[]) => {
    const available: RoomDTO[] = [];
    for (let i = 0; i < rooms.length; i++) {
      if (rooms[i].status === "AVAILABLE") available.push(rooms[i]);
    }
    return available;
  };
  const findNotAvailableRooms = (rooms: RoomDTO[]) => {
    const notAvailable: RoomDTO[] = [];
    for (let i = 0; i < rooms.length; i++) {
      if (rooms[i].status === "NOT_AVAILABLE") notAvailable.push(rooms[i]);
    }
    return notAvailable;
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    allRoomsCall({
      since: props.dateTimeInterval[0].toISOString(),
      till: props.dateTimeInterval[1].toISOString(),
    });
  }, [allRoomsCall, props.dateTimeInterval]);

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={value} onChange={handleChange} aria-label="tabs">
          <Tab label="Všechny" {...a11yProps(0)} />
          <Tab label="Dostupné" {...a11yProps(1)} />
          <Tab label="Nedostupné" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <LoadingGuard state={allRoomsState}>
        <TabPanel value={value} index={0}>
          {allRoomsState.value && (
            <RoomList
              allRooms={allRoomsState.value.data}
              isDetail={props.isDetail}
              isSelected={props.isSelected}
              isStatusAvailable={true}
              since={props.since}
            />
          )}
        </TabPanel>
        <TabPanel value={value} index={1}>
          {allRoomsState.value && (
            <RoomList
              allRooms={findAvailableRooms(allRoomsState.value.data)}
              isDetail={props.isDetail}
              isSelected={props.isSelected}
              isStatusAvailable={true}
            />
          )}
        </TabPanel>
        <TabPanel value={value} index={2}>
          {allRoomsState.value && (
            <RoomList
              allRooms={findNotAvailableRooms(allRoomsState.value.data)}
              isDetail={props.isDetail}
              isSelected={props.isSelected}
              isStatusAvailable={true}
            />
          )}
        </TabPanel>
      </LoadingGuard>
    </Box>
  );
};

export default RoomTabs;
