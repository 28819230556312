import { Typography } from "@mui/material";
import React from "react";

const Unavailable: React.FC = () => {
  return (
    <Typography variant="body1">
      Nemáte dostatečná práva být na této stránce nebo stránka se zadanou url
      neexistuje.
    </Typography>
  );
};

export default Unavailable;
