import { Box } from "@mui/material";
import React, { useContext, useEffect } from "react";

import { useApiClient } from "../../api/useApiClient";
import { useSafeAsync } from "../../hooks/useSafeAsync";
import { defineStyles } from "../../styles/defineStyles";
import AuthContext from "../contexts/AuthContext";
import UiContext from "../contexts/UiContext";
import { LoadingGuard } from "../form/LoadingGuard";
import RequestTable from "../requests/RequestTable";
import AppBarCustom, { BreadCrumbsItem } from "../ui/AppBarCustom";
import Unavailable from "../ui/Unavailable";

import EmptyEventContent from "./EpmtyEventContent";
import EventTable from "./EventTable";

const EventPage: React.FC = () => {
  const styles = defineStyles({
    tabs: {
      marginTop: "20px",
    },
  });

  const roomBreadCrumb: BreadCrumbsItem[] = [
    { name: "Události", path: "/events" },
  ];
  const ctx = useContext(AuthContext);
  const { refresh } = useContext(UiContext);

  const api = useApiClient();
  const [allEventsCall, allEventsState] = useSafeAsync(
    api.findAllRequestsByStatus
  );

  const refreshEventsHandler = () => {
    refresh();
  };

  useEffect(() => {
    allEventsCall({ status: "ACCEPTED" });
  }, [allEventsCall]);

  return (
    <React.Fragment>
      {ctx.isLoggedAdmin && (
        <React.Fragment>
          <AppBarCustom
            isDetail={false}
            title="Události"
            breadCrumb={roomBreadCrumb}
            addOrRemoveButtonCategory="event"
            pathToAdd=""
            isSearchFunctionActive={false}
          />
          <LoadingGuard state={allEventsState}>
            {allEventsState.value && (
              <Box sx={styles.tabs}>
                {allEventsState.value.data.length == 0 && <EmptyEventContent />}
                {allEventsState.value.data.length > 0 && (
                  <EventTable events={allEventsState.value.data} />
                )}
              </Box>
            )}
          </LoadingGuard>
        </React.Fragment>
      )}
      {!ctx.isLoggedAdmin && <Unavailable />}
    </React.Fragment>
  );
};

export default EventPage;
