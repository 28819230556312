import { useTranslation } from "react-i18next";

export const useMinLengthValidation = (chars: number) => {
  const { t } = useTranslation();

  return {
    minLength: {
      value: chars,
      message: t(
        "common.error.minLength",
        "Minimální povolená délka je {{min}} znaků",
        { min: chars }
      ),
    },
  };
};
