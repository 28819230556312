import { Box } from "@mui/material";
import { Dayjs } from "dayjs";
import React from "react";

import { RoomDTO } from "../../api/types";
import { defineStyles } from "../../styles/defineStyles";

import RoomCard from "./RoomCard";

const RoomList: React.FC<{
  allRooms: RoomDTO[];
  isDetail: boolean;
  isSelected: (item: RoomDTO) => void;
  isStatusAvailable: boolean;
  since?: Dayjs;
}> = (props) => {
  const styles = defineStyles({
    rooms: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      p: 0,
    },
  });

  return (
    <Box sx={styles.rooms}>
      {props.allRooms.map((item) => (
        <RoomCard
          room={item}
          key={item.id}
          isDetail={props.isDetail}
          isSelected={props.isSelected}
          isAvailable={props.isStatusAvailable}
          since={props.since}
        />
      ))}
    </Box>
  );
};

export default RoomList;
