import { useCallback, useContext, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import AlertContext from "../components/contexts/AlertContext";
import { destilErrorMessage } from "../api/destilErrorMessage";

// Source: https://github.com/craig1123/react-recipes

export type AsyncState<R> = {
  value: R | null;
  pending: boolean;
  called: boolean;
  error: any | null;
};

export const useSafeAsync = <P, R>(
  asyncFunction: (param?: P) => Promise<R>
): [(param: P) => Promise<R | null>, AsyncState<R>] => {
  const [value, setValue] = useState<R | null>(null);
  const [pending, setPending] = useState(false);
  const [called, setCalled] = useState(false);
  const [error, setError] = useState(null);
  const { showError } = useContext(AlertContext);
  const navigate = useNavigate();

  // useCallback ensures useEffect is not called on every render, but only if asyncFunction changes.
  const call = useCallback(
    async (param: P) => {
      //console.log("Rebuilding call", asyncFunction);
      setError(null);
      setPending(true);
      setValue(null);
      setCalled(true);

      function showErrorImpl(e: any): void {
        if (e.getActualType) {
          const error = e.getActualType();
          console.log(error);
          if (error.status === 403) {
            showError("K operaci nemáte oprávnění");
            navigate("/");
            return;
          }
        }
        showError(destilErrorMessage(e));
      }

      try {
        const result = await asyncFunction(param);
        setValue(result);
        return result;
      } catch (e) {
        showErrorImpl(e);
        setError(e);
        return null;
      } finally {
        setPending(false);
      }
    },
    [asyncFunction, showError, navigate]
  );

  const state = useMemo(
    () => ({
      value,
      pending,
      called,
      error,
    }),
    [error, pending, value, called]
  );
  return [call, state];
};
