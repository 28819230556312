import { Box, Button, Stack } from "@mui/material";
import React, { useContext } from "react";
import { FormContainer, TextFieldElement } from "react-hook-form-mui";
import { useNavigate } from "react-router-dom";

import { useApiClient } from "../../api/useApiClient";
import { useSafeAsync } from "../../hooks/useSafeAsync";
import { defineStyles } from "../../styles/defineStyles";
import UiContext from "../contexts/UiContext";
import { AsyncButton } from "../form/AsyncButton";
import { useRequiredValidation } from "../models/settings";
import AppBarCustom, { BreadCrumbsItem } from "../ui/AppBarCustom";

const NewClient: React.FC = () => {
  const styles = defineStyles({
    main: {
      mt: 9,
      ml: "calc((100vw - 788px)/2)",
      alignItem: "center",
      width: "500px",
    },
    formItem: {
      width: "100%",
      mt: 3,
    },
  });
  const newClientBreadCrumb: BreadCrumbsItem[] = [
    { name: "Klienti", path: "/clients" },
    { name: "Nový účet", path: "" },
  ];

  const required = useRequiredValidation();
  const navigate = useNavigate();
  const path = "/clients";
  const { refresh } = useContext(UiContext);

  const { createClient } = useApiClient();

  const [createClientCall, createClientState] = useSafeAsync(createClient);

  const submitHandler = async (data: {
    companyName: string;
    companyDescription: string;
  }) => {
    await createClientCall({
      name: data.companyName,
      description: data.companyDescription,
    });
    refresh();
    navigate(path);
  };

  return (
    <React.Fragment>
      <AppBarCustom
        isDetail={true}
        title="Nový klient"
        breadCrumb={newClientBreadCrumb}
        addOrRemoveButtonCategory="client"
        pathToAdd=""
        isSearchFunctionActive={false}
      />
      <Box sx={styles.main}>
        <FormContainer defaultValues={{}} onSuccess={submitHandler}>
          <TextFieldElement
            name="companyName"
            id="comanyName"
            validation={required}
            label="Název společnosti"
            sx={styles.formItem}
          />
          <TextFieldElement
            name="companyDescription"
            id="comanyDescription"
            validation={required}
            label="Popis společnosti"
            sx={styles.formItem}
            multiline
            rows={2}
          />
          <Stack direction="row" sx={styles.formItem}>
            <div className="space"></div>
            <AsyncButton variant="contained" state={createClientState}>
              Vytvořit klienta
            </AsyncButton>
          </Stack>
        </FormContainer>
      </Box>
    </React.Fragment>
  );
};

export default NewClient;
