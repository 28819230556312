import React, { useContext } from "react";

import { defineStyles } from "../../styles/defineStyles";
import AuthContext from "../contexts/AuthContext";
import AppBarCustom, { BreadCrumbsItem } from "../ui/AppBarCustom";
import Unavailable from "../ui/Unavailable";

import ClientsTable from "./ClientsTable";

const ClientsPage: React.FC = () => {
  const styles = defineStyles({});

  const ctx = useContext(AuthContext);

  const clientsBreadCrumbs: BreadCrumbsItem[] = [
    { name: "Klienti", path: "/clients" },
  ];

  return (
    <React.Fragment>
      {!ctx.isLoggedAdmin && <Unavailable />}
      {ctx.isLoggedAdmin && (
        <React.Fragment>
          <AppBarCustom
            isDetail={false}
            title="Klienti"
            breadCrumb={clientsBreadCrumbs}
            addOrRemoveButtonCategory="client"
            pathToAdd="/clients/new/"
            isSearchFunctionActive={true}
          />
          <ClientsTable />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default ClientsPage;
