import { Check, Close } from "@mui/icons-material";
import { Box, Button, Stack, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";

import { defineStyles } from "../../styles/defineStyles";
import AuthContext from "../contexts/AuthContext";
import AppBarCustom, { BreadCrumbsItem } from "../ui/AppBarCustom";
import DetailForm from "../ui/DetailForm";
import CustomDialog from "../ui/CustomDialog";
import { useApiClient } from "../../api/useApiClient";
import { useSafeAsync } from "../../hooks/useSafeAsync";
import { LoadingGuard } from "../form/LoadingGuard";
import UiContext from "../contexts/UiContext";
import { RequestDTO } from "../../api/types";

import NewRequest from "./NewRequest";

const EditRequest: React.FC = () => {
  const styles = defineStyles({
    main: {
      mt: 9,
      ml: "calc((100vw - 888px)/2)",
      alignItem: "center",
      width: "600px",
    },
    icon: {
      mr: 1,
      width: "16px",
      height: "18px",
    },
    button: {
      ml: 2,
      alignItems: "center",
    },
    buttonText: {
      fontSize: "13px",
    },
  });

  const [showDialog, setShowDialog] = useState<boolean>(false);
  const ctx = useContext(AuthContext);
  const navigate = useNavigate();
  const path = "/requests/";
  const data = useParams();
  const requestId = parseInt(data.id);

  const { refresh } = useContext(UiContext);

  const api = useApiClient();
  const [currentRequestCall, currentRequestState] = useSafeAsync(
    api.findRequestById
  );
  const [updateRequestStatusCall, updateRequestStatusState] = useSafeAsync(
    api.updateRequestStatus
  );

  const [requestToCopy, setRequestToCopy] = useState<boolean>(false);

  const editRequestBreadcrumb: BreadCrumbsItem[] = [
    { name: "Žádosti", path: "/requests" },
    { name: "Detail žádosti", path: "" },
  ];

  const acceptRequestHandler = async () => {
    // nastaveni requestu jako "potvrzena"
    const result = await updateRequestStatusCall({
      id: requestId,
      requestId: requestId,
      status: "ACCEPTED",
      force: false,
    });
    if (result != null) {
      refresh();
      navigate(-1);
    }
  };

  const deneidRequestHandler = () => {
    // otevreni dialogoveho okna s komentarem k zamitnuti
    setShowDialog(true);
  };

  const closeDialogHandler = () => {
    // zavreni dialogu(schovani)
    setShowDialog(false);
  };

  const requestDeniedHandler = async (comment: string) => {
    // akce po stisknuti tlacitka v dialogu
    setShowDialog(false);

    // nastaveni zadosti jako zamitnuta
    const result = await updateRequestStatusCall({
      id: requestId,
      requestId: requestId,
      status: "DECLINED",
      force: false,
    });
    if (result != null) {
      navigate(-1);
    }
  };

  const onSubmitActionHandler = () => {
    // funkce pro ulozeni formulare, v tomto pripade se neuklada
    //donothing
  };

  const goToNewRequestWithCurrentDataHandler = () => {
    //zobrazime formular na novy request ale predavame soucasny request v props
    if (currentRequestState.value == null) return;
    setRequestToCopy(true);
  };

  useEffect(() => {
    currentRequestCall({ id: requestId });
  }, [currentRequestCall, requestId]);

  return (
    <React.Fragment>
      {!requestToCopy && (
        <React.Fragment>
          <AppBarCustom
            isDetail={false}
            title="Detail žádosti"
            breadCrumb={editRequestBreadcrumb}
            addOrRemoveButtonCategory="request"
            pathToAdd={""}
            isSearchFunctionActive={false}
          />
          <Box sx={styles.main}>
            <LoadingGuard state={currentRequestState}>
              {currentRequestState.value && (
                <DetailForm
                  title="Žádost"
                  request={currentRequestState.value}
                  isSubmitAllowed={false}
                  onSubmitAction={onSubmitActionHandler}
                />
              )}
              {ctx.isLoggedAdmin &&
                currentRequestState.value &&
                currentRequestState.value.status === "REQUEST" && (
                  <Stack direction="row" sx={{ mt: 3 }}>
                    <div className="space"></div>
                    <Button
                      variant="contained"
                      sx={styles.button}
                      color="success"
                      onClick={acceptRequestHandler}
                    >
                      <Check sx={styles.icon} />
                      <Typography sx={styles.buttonText}>Potvrdit</Typography>
                    </Button>
                    <Button
                      variant="contained"
                      sx={styles.button}
                      color="error"
                      onClick={deneidRequestHandler}
                    >
                      <Close sx={styles.icon} />
                      <Typography sx={styles.buttonText}>Zamítnout</Typography>
                    </Button>
                  </Stack>
                )}
              {!ctx.isLoggedAdmin && (
                <Stack direction="row" sx={{ mt: 3 }}>
                  <div className="space"></div>
                  <Button
                    variant="contained"
                    sx={styles.button}
                    color="primary"
                    onClick={goToNewRequestWithCurrentDataHandler}
                  >
                    Požádat znova
                  </Button>
                </Stack>
              )}
            </LoadingGuard>
          </Box>
          {showDialog && (
            <CustomDialog
              openDialog={showDialog}
              closed={closeDialogHandler}
              title="Zamítnutí žádosti"
              subtitle="Předtím než definitivně žádost zamítnete, udejte prosím důvod. Zvýšíte tak efektivitu komunikace s klientem."
              commentLabel="Důvod zamítnutí žádosti"
              commentPlaceholder="Nevhodný termín, zkuste prosím jiný."
              buttonText="Zamítnout žádost"
              onButtonClick={requestDeniedHandler}
            />
          )}
        </React.Fragment>
      )}
      {requestToCopy && <NewRequest prevRequest={currentRequestState.value} />}
    </React.Fragment>
  );
};

export default EditRequest;
