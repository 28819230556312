import { DataGrid, GridColDef, GridEventListener } from "@mui/x-data-grid";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useApiClient } from "../../api/useApiClient";
import { useSafeAsync } from "../../hooks/useSafeAsync";
import { defineStyles } from "../../styles/defineStyles";
import { LoadingGuard } from "../form/LoadingGuard";

const ClientsTable: React.FC = () => {
  const styles = defineStyles({
    table: {
      mt: 3,
      minHeight: "640px",
    },
    head: {
      fontWeight: "bold",
      width: "100%",
    },
    headName: {
      fontWeight: "bold",
      width: "40%",
    },
    headDescription: {
      fontWeight: "bold",
      width: "40%",
    },
    headItem: {
      fontWeight: "bold",
      width: "10%",
    },
  });

  const navigate = useNavigate();
  const [pageSize, setPageSize] = useState(10);

  const api = useApiClient();
  const [allClientsCall, allClientsState] = useSafeAsync(api.findAllClients);

  const clientsColumns: GridColDef[] = [
    { field: "name", headerName: "Název společnosti", flex: 1 },
    { field: "description", headerName: "Popis", flex: 1 },
    { field: "eventCount", headerName: "Počet událostí", flex: 0.5 },
    { field: "requestCount", headerName: "Počet žádostí", flex: 0.5 },
    { field: "deleteDate", headerName: "Odstraneno", flex: 0.5 },
  ];

  const clientsRows = useMemo(() => {
    if (allClientsState.value == null) {
      return [];
    }
    return allClientsState.value.data.map((client) => {
      return {
        id: client.id,
        name: client.name,
        description: client.description,
        eventCount: client.eventsCount,
        requestCount: client.requestsCount,
        deleteDate: client.dateDeleted,
      };
    });
  }, [allClientsState.value]);

  const clientDetailHandler: GridEventListener<"rowClick"> = (params) => {
    const path = "/clients/" + params.id.toString();
    navigate(path);
  };

  useEffect(() => {
    allClientsCall({});
  }, [allClientsCall]);

  return (
    <LoadingGuard state={allClientsState}>
      <DataGrid
        rows={clientsRows}
        columns={clientsColumns}
        sx={styles.table}
        onRowClick={clientDetailHandler}
        rowsPerPageOptions={[10, 25, 50]}
        pageSize={pageSize}
        onPageSizeChange={(newPage) => setPageSize(newPage)}
      />
    </LoadingGuard>
  );
};

export default ClientsTable;
