export const destilErrorMessage = (e?: any) => {
  console.log("Incomming error", e);
  console.log("Incomming error type", typeof e);
  if (e == null) return "Neznámá chyba / Unknown error";
  let suffix = "";
  if (e.data && e.data?.errorId) {
    suffix = ` (ID chyby: ${e.data?.errorId})`;
  }
  if (e.data && e.data?.message) return e.data.message + suffix;
  //if (e.)
  if (e.data && e.data?.code == 401)
    return "Nedostatečné oprávnění / Permission denied" + suffix;
  if (e.data && e.data?.code == 404)
    return "Záznam nenalezen / Record not found" + suffix;
  if (e.data && e.data?.code && parseInt(e.data?.code) >= 500)
    return "Chyba serveru / Server error" + suffix;
  if (e.message) {
    if (e.message.toString().startsWith("Failed to fetch"))
      return "Chyba připojení / Cannot connect";
    return e.message.toString();
  }
  if (e.description) return e.description.toString() + suffix;
  if (e.error) return e.error.toString() + suffix;
  return JSON.stringify(e) + suffix;
};
