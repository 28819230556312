import { CalendarToday } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import dayjs, { Dayjs } from "dayjs";
import React, { useState } from "react";

import { RoomDTO } from "../../api/types";
import { useApiClient } from "../../api/useApiClient";
import { useSafeAsync } from "../../hooks/useSafeAsync";
import { defineStyles } from "../../styles/defineStyles";
import { LoadingGuard } from "../form/LoadingGuard";

const CalendarLink: React.FC<{
  since?: Dayjs;
  room: RoomDTO;
}> = (props) => {
  const styles = defineStyles({
    link: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      textDecoration: "none",
      alignItems: "center",
    },
    icon: {
      p: 0,
      pr: 1,
    },
    text: {},
  });

  const api = useApiClient();

  const [showRoomCalendar, setShowRoomCalendar] = useState<boolean>(false);
  const [roomCalendarDataCall, roomCalendarDataState] = useSafeAsync(
    api.getRoomCalendarData
  );

  const dayInWeek = [
    "Neděle",
    "Pondělí",
    "Úterý",
    "Středa",
    "Čtvrtek",
    "Pátek",
    "Sobota",
  ];

  const daysAfterDate = 30;

  const showRoomCalendarHandler = async () => {
    setShowRoomCalendar(true);
    let result = null;
    if (props.since) {
      result = await roomCalendarDataCall({
        roomId: props.room.id,
        since: props.since.toISOString(),
        periodDays: daysAfterDate,
      });
    } else {
      result = await roomCalendarDataCall({
        roomId: props.room.id,
        periodDays: daysAfterDate,
      });
    }
  };

  const getCalendarEventsByDays = (
    dataEvents: { since: string; till: string; name: string }[] | []
  ) => {
    const calendarData: {
      dayName: string;
      date: Dayjs;
      events: { since: string; till: string; name: string }[];
    }[] = [];
    for (let a = 0; a < daysAfterDate; a++) {
      calendarData.push({
        dayName:
          dayInWeek[
            dayjs(
              props.since ? props.since.add(a, "day") : dayjs().add(a, "day")
            ).get("day")
          ],
        date: props.since ? props.since.add(a, "day") : dayjs().add(a, "day"),
        events: [],
      });
    }
    if (dataEvents.length === 0) return calendarData;

    for (let i = 0; i < dataEvents.length; i++) {
      for (let j = 0; j < calendarData.length; j++) {
        if (
          dayjs(dataEvents[i].since).get("date") ===
          dayjs(calendarData[j].date).get("date")
        ) {
          calendarData[j].events.push(dataEvents[i]);
        }
      }
    }
    return calendarData;
  };

  const onCloseHandler = () => {
    setShowRoomCalendar(false);
  };

  return (
    <React.Fragment>
      <Button
        variant="text"
        onClick={() => {
          showRoomCalendarHandler();
        }}
        sx={styles.text}
      >
        <CalendarToday sx={styles.icon} />
        Obsazení
      </Button>
      {showRoomCalendar && (
        <Dialog open={showRoomCalendar} onClose={onCloseHandler}>
          <DialogTitle>
            Termíny obsazení místnosti {props.room.name}
          </DialogTitle>
          <LoadingGuard state={roomCalendarDataState}>
            {roomCalendarDataState.value && (
              <DialogContent>
                {getCalendarEventsByDays(roomCalendarDataState.value.events)
                  .length > 0 &&
                  getCalendarEventsByDays(
                    roomCalendarDataState.value.events
                  ).map((day) => (
                    <Box key={day.dayName}>
                      <h5>
                        {day.dayName} {day.date.format("DD.MM.YYYY")}
                      </h5>
                      {day.events.length === 0 && (
                        <Typography variant="body2" key={day.dayName}>
                          Žádné události
                        </Typography>
                      )}
                      {day.events.length > 0 && (
                        <Table>
                          {/* <TableHead>
                            <TableRow>
                              <TableCell>Od</TableCell>
                              <TableCell>Do</TableCell>
                              <TableCell>Název události</TableCell>
                            </TableRow>
                          </TableHead> */}
                          <TableBody>
                            {day.events.map((event) => (
                              <TableRow
                                key={event.since + Math.random().toString()}
                              >
                                <TableCell>
                                  {dayjs(event.since).format("HH:mm")}
                                </TableCell>
                                <TableCell>
                                  {dayjs(event.till).format("HH:mm")}
                                </TableCell>
                                <TableCell>
                                  {event.name === "-" ? "Obsazeno" : event.name}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      )}
                    </Box>
                  ))}
              </DialogContent>
            )}
          </LoadingGuard>
          <DialogActions>
            <Stack direction="row">
              <div className="space"></div>
              <Button onClick={onCloseHandler}>Zavřít</Button>
            </Stack>
          </DialogActions>
        </Dialog>
      )}
    </React.Fragment>
  );
};

export default CalendarLink;
