import { Fetcher } from "openapi-typescript-fetch";

import { paths } from "./generated/green-table-api";
import { safe404call } from "./safe404call";

export const buildApiClient = (baseUrl: string, authKey?: string) => {
  const f = Fetcher.for<paths>();
  f.configure({
    baseUrl: baseUrl,
    init: {
      headers: authKey != null ? { Authorization: authKey } : {},
    },
  });
  return {
    findAllClients: safe404call(
      f.path("/api/v1/clients").method("get").create()
    ),
    findClientById: safe404call(
      f.path("/api/v1/clients/{id}").method("get").create()
    ),
    createClient: safe404call(
      f.path("/api/v1/clients").method("post").create()
    ),
    updateClient: safe404call(
      f.path("/api/v1/clients/{clientId}").method("put").create()
    ),
    deleteClient: safe404call(
      f.path("/api/v1/clients/{clientId}").method("delete").create()
    ),
    findAllUsers: safe404call(f.path("/api/v1/users").method("get").create()),
    findAllUsersByClientId: safe404call(
      f.path("/api/v1/clients/{clientId}/users").method("get").create()
    ),
    findUserById: safe404call(
      f.path("/api/v1/users/{id}").method("get").create()
    ),
    findUserMe: safe404call(f.path("/api/v1/users/me").method("get").create()),
    findUserByParams: safe404call(
      f.path("/api/v1/users/filter").method("get").create()
    ),
    updateUser: safe404call(f.path("/api/v1/users/me").method("put").create()),
    deleteUser: safe404call(
      f.path("/api/v1/users/{userId}").method("delete").create()
    ),
    restoreUser: safe404call(
      f.path("/api/v1/users/{userId}/enable").method("put").create()
    ),
    findAllRequests: safe404call(
      f.path("/api/v1/requests").method("get").create()
    ),
    findAllRequestsByClientId: safe404call(
      f.path("/api/v1/clients/{clientId}/requests").method("get").create()
    ),
    findAllRequestsByStatus: safe404call(
      f.path("/api/v1/requests/filter").method("get").create()
    ),
    findRequestById: safe404call(
      f.path("/api/v1/requests/{id}").method("get").create()
    ),
    createRequest: safe404call(
      f.path("/api/v1/requests").method("post").create()
    ),
    updateRequestStatus: safe404call(
      f.path("/api/v1/requests/{id}/status").method("put").create()
    ),
    updateRequest: safe404call(
      f.path("/api/v1/requests/{id}").method("put").create()
    ),
    deleteRequest: safe404call(
      f.path("/api/v1/requests/{id}/delete").method("put").create()
    ),
    createUser: safe404call(f.path("/api/v1/users").method("post").create()),
    findAllRooms: safe404call(f.path("/api/v1/rooms").method("get").create()),
    findRoomById: safe404call(
      f.path("/api/v1/rooms/{id}").method("get").create()
    ),
    findAllRoomsWithStatusInDateInterval: safe404call(
      f.path("/api/v1/rooms/filter").method("get").create()
    ),
    findMyNotification: safe404call(
      f.path("/api/v1/notifications/my").method("get").create()
    ),
    findMyNotificationCount: safe404call(
      f.path("/api/v1/notifications/my/count").method("get").create()
    ),
    markNotificationAsRead: safe404call(
      f
        .path("/api/v1/notifications/{notificationId}/read")
        .method("put")
        .create()
    ),
    loginUser: safe404call(f.path("/api/v1/sessions").method("post").create()),
    getLogged: safe404call(f.path("/api/v1/sessions").method("get").create()),
    setUserPassword: safe404call(
      f.path("/api/v1/sessions/first-password").method("post").create()
    ),
    updateUserPassword: safe404call(
      f.path("/api/v1/users/{id}/password").method("put").create()
    ),
    changePasswordRequest: safe404call(
      f.path("/api/v1/sessions/change-password/request").method("post").create()
    ),
    changePassword: safe404call(
      f.path("/api/v1/sessions/change-password").method("post").create()
    ),
    echo: safe404call(f.path("/api/v1/echo/hello").method("post").create()),
    googleLoginViaToken: safe404call(
      f.path("/api/v1/social/google").method("post").create()
    ),
    getRoomCalendarData: safe404call(
      f.path("/api/v1/rooms/{roomId}/calendar").method("get").create()
    ),
  };
};
