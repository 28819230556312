import React, { useCallback, useContext, useEffect } from "react";
import { SxProps } from "@mui/material";

import { useScript } from "../../hooks/useScript";
import { AsyncButton } from "../form/AsyncButton";
import { useApiClient } from "../../api/useApiClient";
import { useSafeAsync } from "../../hooks/useSafeAsync";
import AuthContext from "../contexts/AuthContext";

type GoogleButtonProps = {
  children?: React.ReactNode;
  sx?: SxProps;
};

function startSignIn(callback: (response: string) => void) {
  let redirectUri = new URL("/", window.location.origin).toString();
  if (!redirectUri.endsWith("/")) redirectUri += "/";

  const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
  console.log(
    "Starting GOOGLOGIN with redirect_uri=" +
      redirectUri +
      " and client_id=" +
      clientId +
      " (those values must match server side, search for GOOGLOGIN in server log)"
  );
  const c = google.accounts.oauth2.initCodeClient({
    client_id: clientId,
    scope: "openid email profile",
    redirect_uri: redirectUri,
    callback: function (codeResponse: any) {
      callback(codeResponse.code);
    },
  });
  c.requestCode();
}

export const GoogleScript = () => {
  const url = "https://accounts.google.com/gsi/client";
  const script = useScript(url);
  return <></>;
};

export const GoogleButton = (props: GoogleButtonProps) => {
  const { googleLoginViaToken } = useApiClient();
  const { setLoggedUser } = useContext(AuthContext);

  const [googleLoginViaTokenCall, googleLoginViaTokenState] =
    useSafeAsync(googleLoginViaToken);

  const receiveGoogleToken = useCallback<(oauthCode: string) => void>(
    (oauthCode: string) => {
      console.log("Incomming Google token");
      googleLoginViaTokenCall({ value: oauthCode });
    },
    [googleLoginViaTokenCall]
  );

  useEffect(() => {
    if (googleLoginViaTokenState.value) {
      console.log("User is logged through Google");
      setLoggedUser(googleLoginViaTokenState.value);
    }
  }, [setLoggedUser, googleLoginViaTokenState.value]);

  const startProcessHandler = () => startSignIn(receiveGoogleToken);

  return (
    <AsyncButton
      onClick={startProcessHandler}
      state={googleLoginViaTokenState}
      variant={"outlined"}
      sx={props.sx}
    >
      Přihlášení Google
    </AsyncButton>
  );
};

export default GoogleButton;
