import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const navigate = useNavigate();
  const path = "/requests";

  useEffect(() => {
    navigate(path);
  }, [navigate]);

  return <div></div>;
};

export default Home;
