import { useContext, useMemo } from "react";

import AuthContext from "../components/contexts/AuthContext";

import { buildApiClient } from "./buildApiClient";

export const useApiClient = () => {
  const c = useContext(AuthContext);

  if (c == null) {
    throw new Error("ApiClientContext is null");
  }

  const authKeyToUse = c?.loggedUser?.authToken;

  return useMemo(
    () => buildApiClient(process.env.REACT_APP_SERVER_ROOT, authKeyToUse),
    [authKeyToUse]
  );
};
