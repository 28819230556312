import { Box, Typography } from "@mui/material";
import React from "react";

const EmptyEventContent: React.FC = () => {
  return (
    <Box>
      <Typography variant="body1">Žádná událost</Typography>
    </Box>
  );
};

export default EmptyEventContent;
