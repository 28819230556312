import { Box } from "@mui/material";
import React, { useContext, useEffect } from "react";

import { RoomDTO } from "../../api/types";
import { useApiClient } from "../../api/useApiClient";
import { useSafeAsync } from "../../hooks/useSafeAsync";
import { defineStyles } from "../../styles/defineStyles";
import AuthContext from "../contexts/AuthContext";
import { LoadingGuard } from "../form/LoadingGuard";
import AppBarCustom, { BreadCrumbsItem } from "../ui/AppBarCustom";
import Unavailable from "../ui/Unavailable";

import RoomList from "./RoomList";

export type Room = {
  id: number;
  name: string;
  description?: string;
  imgUrl?: string;
  capacity: number;
  status: "AVAILABLE" | "NOT_AVAILABLE";
  equipment?: string[];
  calendarUrl?: string;
};

export const RoomsPage: React.FC = () => {
  const styles = defineStyles({
    tabs: {
      marginTop: "20px",
    },
  });

  const roomBreadCrumb: BreadCrumbsItem[] = [
    { name: "Místnosti", path: "/rooms" },
  ];
  const ctx = useContext(AuthContext);
  const api = useApiClient();
  const [allRoomsCall, allRoomsState] = useSafeAsync(api.findAllRooms);

  const isSelectedRoomHandler = (item: RoomDTO) => {
    return item;
  };

  useEffect(() => {
    allRoomsCall({});
  }, [allRoomsCall]);

  return (
    <React.Fragment>
      {ctx.isLoggedAdmin && (
        <React.Fragment>
          <AppBarCustom
            isDetail={false}
            title="Místnosti"
            breadCrumb={roomBreadCrumb}
            addOrRemoveButtonCategory="room"
            pathToAdd={""}
            isSearchFunctionActive={false}
          />
          <Box sx={styles.tabs}>
            {/* <RoomTabs isDetail={true} isSelected={isSelectedRoomHandler} /> */}
            <LoadingGuard state={allRoomsState}>
              {allRoomsState.value && (
                <RoomList
                  allRooms={allRoomsState.value.data}
                  isDetail={true}
                  isSelected={isSelectedRoomHandler}
                  isStatusAvailable={false}
                />
              )}
            </LoadingGuard>
          </Box>
        </React.Fragment>
      )}
      {!ctx.isLoggedAdmin && <Unavailable />}
    </React.Fragment>
  );
};
