import { Notifications } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridEventListener,
  GridRowParams,
} from "@mui/x-data-grid";
import dayjs from "dayjs";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { NotificationDTO } from "../../api/types";
import { useApiClient } from "../../api/useApiClient";
import { useSafeAsync } from "../../hooks/useSafeAsync";
import { defineStyles } from "../../styles/defineStyles";
import AuthContext from "../contexts/AuthContext";
import { LoadingGuard } from "../form/LoadingGuard";

export const getNotificationDescription = (
  notification: NotificationDTO,
  isLoggedAdmin: boolean
) => {
  // tady vygenerujeme podobu textu bud pro admina nebo pro uzika
  if (isLoggedAdmin) {
    return (
      <React.Fragment>
        <Typography component="span" variant="body2">
          Nová žádost{" "}
        </Typography>
        &nbsp;
        <Typography
          component="span"
          variant="body2"
          sx={{ fontWeight: "bold" }}
        >
          {notification.requestDTO.name}
        </Typography>
        &nbsp;
        <Typography component="span" variant="body2">
          {" "}
          na{" "}
        </Typography>
        &nbsp;
        <Typography component="span" variant="body2">
          {dayjs(notification.requestDTO.since).format("DD.MM.YYYY")}
        </Typography>
        &nbsp;
        <Typography component="span" variant="body2">
          {" "}
          v{" "}
        </Typography>
        &nbsp;
        <Typography
          component="span"
          variant="body2"
          sx={{ fontWeight: "bold" }}
        >
          {notification.requestDTO.roomDTO.name}
        </Typography>
        &nbsp;
        <Typography component="span" variant="body2">
          {" "}
          z účtu{" "}
        </Typography>
        &nbsp;
        <Typography component="span" variant="body2" sx={{}}>
          {notification.requestDTO.userDTO.email},{" "}
        </Typography>
        &nbsp;
        {/* 
        <Typography component="span" variant="body2"></Typography>
        &nbsp; */}
        <Typography component="span" variant="body2" sx={{}}>
          {notification.requestDTO.clientDTO.name}.
        </Typography>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <Typography component="span" variant="body2">
          Vaše žádost{" "}
        </Typography>
        &nbsp;
        <Typography
          component="span"
          variant="body2"
          sx={{ fontWeight: "bold" }}
        >
          {notification.requestDTO.name}{" "}
        </Typography>
        &nbsp;
        {notification.requestDTO.status === "ACCEPTED" && (
          <Typography component="span" variant="body2">
            {" "}
            byla schválená.
          </Typography>
        )}
        {notification.requestDTO.status === "DECLINED" && (
          <Typography component="span" variant="body2">
            {" "}
            byla zamítnutá.
          </Typography>
        )}
      </React.Fragment>
    );
  }
};

const NotificationTable: React.FC = () => {
  const styles = defineStyles({
    main: {
      mt: 9,
      ml: "calc((100vw - 1288px)/2)",
      alignItem: "center",
      width: "1000px",
    },
    table: {
      mt: 3,
      minHeight: "640px",
    },
  });

  const ctx = useContext(AuthContext);
  const navigate = useNavigate();
  const [pageSize, setPageSize] = useState(10);

  const api = useApiClient();
  const [myNotificationCall, myNotificationState] = useSafeAsync(
    api.findMyNotification
  );
  const [markAsReadNotificationCall, markAsReadNotificationState] =
    useSafeAsync(api.markNotificationAsRead);

  const notificationColumns: GridColDef[] = [
    {
      field: "icon",
      headerName: "",
      flex: 0.1,
      renderCell: ({ row }: Partial<GridRowParams>) => <Notifications />,
    },
    {
      field: "notificationText",
      headerName: "",
      flex: 1,
      renderCell: ({ row }: Partial<GridRowParams>) =>
        getNotificationDescription(row.notification, ctx.isLoggedAdmin),
    },
    { field: "createTime", headerName: "", flex: 0.2 },
  ];

  const notificationRows = useMemo(() => {
    if (myNotificationState.value == null) {
      return [];
    }
    return myNotificationState.value.data.map((notification) => {
      return {
        id: notification.id,
        createTime: dayjs(notification.dateCreated).format("DD.MM.YYYY HH:mm"),
        notification: notification,
      };
    });
  }, [myNotificationState.value]);

  const findRequestIdByNotificationId = (id: number) => {
    for (let i = 0; i < myNotificationState.value.data.length; i++) {
      if (id === myNotificationState.value.data[i].id) {
        return myNotificationState.value.data[i].requestDTO.id;
      }
    }
    return 0;
  };

  const notificationRowClickHandler: GridEventListener<"rowClick"> = async (
    params
  ) => {
    const path =
      "/requests/" +
      findRequestIdByNotificationId(parseInt(params.id.toString()));
    /* if (ctx.isLoggedAdmin) {
      path =
        path + findRequestIdByNotificationId(parseInt(params.id.toString()));
    } */
    await markAsReadNotificationCall({
      notificationId: parseInt(params.id.toString()),
    });
    navigate(path);
  };

  useEffect(() => {
    myNotificationCall({});
  }, [myNotificationCall]);

  return (
    <Box sx={styles.main}>
      <LoadingGuard state={myNotificationState}>
        {myNotificationState.value &&
          myNotificationState.value.data.length == 0 && (
            <Typography sx={{ mt: 2 }}>
              Nenalezli jsme žádnou notifikaci.
            </Typography>
          )}
        {myNotificationState.value &&
          myNotificationState.value.data.length > 0 && (
            <DataGrid
              rows={notificationRows}
              columns={notificationColumns}
              sx={styles.table}
              pageSize={pageSize}
              rowsPerPageOptions={[10, 25, 50]}
              onPageSizeChange={(pageSize) => setPageSize(pageSize)}
              onRowClick={notificationRowClickHandler}
            />
          )}
      </LoadingGuard>
    </Box>
  );
};

export default NotificationTable;
