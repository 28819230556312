import { Box } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";

import { defineStyles } from "../../styles/defineStyles";
import AppBarCustom, { BreadCrumbsItem } from "../ui/AppBarCustom";

import UserTable from "./UserTable";

const UserPage: React.FC = () => {
  const styles = defineStyles({
    main: {
      mt: 3,
    },
  });
  const data = useParams();
  const clientId = parseInt(data.id);
  const path = "/clients/" + clientId + "/user/new";
  const userBreadCrumb: BreadCrumbsItem[] = [{ name: "", path: "" }];

  return (
    <Box sx={styles.main}>
      <AppBarCustom
        isDetail={false}
        title="Účty"
        breadCrumb={userBreadCrumb}
        addOrRemoveButtonCategory="user"
        pathToAdd={path}
        isSearchFunctionActive={true}
      />
      <UserTable />
    </Box>
  );
};

export default UserPage;
