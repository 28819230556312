import { MoreHorizRounded, Notifications } from "@mui/icons-material";
import {
  Avatar,
  Badge,
  Box,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { defineStyles } from "../styles/defineStyles";
import { useSafeAsync } from "../hooks/useSafeAsync";
import { useApiClient } from "../api/useApiClient";

import AuthContext from "./contexts/AuthContext";
import { LoadingGuard } from "./form/LoadingGuard";
import { getNotificationDescription } from "./notification/NotificationTable";
import UiContext from "./contexts/UiContext";
import "../index.css";

const Navigation = () => {
  const ctx = useContext(AuthContext);
  const style = defineStyles({
    menu: {
      width: "calc(100% - 64px)",
      height: "64px",
      display: "flex",
      alignItems: "center",
      textAlign: "center",
      boxShadow:
        "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)",
      position: "fixed",
      left: "64px",
      top: "0",
      zIndex: "90",
      backgroundColor: "white",
      marginBottom: "15px",
    },
    icon: {
      width: "48px",
      height: "48px",
      mr: 1,
    },
    loginName: {
      pl: 3,
      fontSize: "20px",
      fontWeight: "500",
      color: "rgba(0, 0, 0, 0.6)",
    },
    avatar: {
      width: "40px",
      height: "40px",
      marginRight: "48px",
    },
    menuItemText: {
      fontSize: "16px",
      color: "rgba(0, 0, 0, 0.87)",
    },
    menuItemSubtext: {
      fontSize: "14px",
      color: "rgba(0, 0, 0, 0.6)",
    },
    iconNotification: {
      width: "24px",
      height: "24px",
      mr: 4,
    },
    bold: {
      fontWeigth: "bold",
    },
    notificationRow: {
      alignItems: "center",
    },
  });

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorElTwo, setAnchorElTwo] = React.useState<null | HTMLElement>(
    null
  );
  const openUser = Boolean(anchorEl);
  const openNotification = Boolean(anchorElTwo);
  const handleClickUser = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseUser = () => {
    setAnchorEl(null);
  };
  const handleClickNotification = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElTwo(event.currentTarget);
  };
  const handleCloseNotification = () => {
    setAnchorElTwo(null);
  };

  const loggedUser = ctx.loggedUser.userDTO;
  const avatarName = loggedUser.email.charAt(0).toUpperCase();

  const navigate = useNavigate();
  const { refresh } = useContext(UiContext);

  const api = useApiClient();
  const [loggedClientCall, loggedClientState] = useSafeAsync(
    api.findClientById
  );

  const [myNotificationsCall, myNotificationsState] = useSafeAsync(
    api.findMyNotification
  );
  const [myNotificationsCountCall, myNotificationsCountState] = useSafeAsync(
    api.findMyNotificationCount
  );

  const [markAsReadNotificationCall, markAsReadNotificationState] =
    useSafeAsync(api.markNotificationAsRead);

  const showedName = useMemo(() => {
    if (ctx.isLoggedAdmin) {
      return "Admin";
    } else {
      if (loggedClientState.value == null) {
        return "";
      }
      return loggedClientState.value.name;
    }
  }, [loggedClientState.value, ctx.isLoggedAdmin]);

  const goToNotificationsHandler = () => {
    const path = "/notifications/";
    refresh();
    navigate(path);
  };

  const goToSettingsHandler = () => {
    const path = "/settings/";
    refresh();
    navigate(path);
  };

  const goToRequestAndMarkAsReadHandler = async (
    notificaitonId: number,
    requestId: number
  ) => {
    await markAsReadNotificationCall({ notificationId: notificaitonId });
    refresh();
    const path = "/requests/" + requestId;
    /* if (ctx.isLoggedAdmin) {
      path = path + requestId;
    } */
    navigate(path);
  };

  useEffect(() => {
    myNotificationsCall({ unReadOnly: true });
    myNotificationsCountCall({ unReadOnly: true });
    if (!ctx.isLoggedAdmin) {
      loggedClientCall({ id: ctx.loggedUser.userDTO.clientId });
    }
  }, [ctx, loggedClientCall, myNotificationsCall, myNotificationsCountCall]);

  return (
    <Stack direction="row" sx={style.menu}>
      {ctx.isLoggedAdmin && (
        <Typography sx={style.loginName}>{showedName}</Typography>
      )}
      {!ctx.isLoggedAdmin && (
        <LoadingGuard state={loggedClientState}>
          <Typography sx={style.loginName}>{showedName}</Typography>
        </LoadingGuard>
      )}
      <div className="space"></div>

      <LoadingGuard state={myNotificationsState}>
        <LoadingGuard state={myNotificationsCountState}>
          {myNotificationsState.value && myNotificationsCountState && (
            <Tooltip title="Notifikace" sx={style.icon}>
              <IconButton
                onClick={handleClickNotification}
                aria-controls={open ? "notification-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
              >
                <Badge
                  badgeContent={myNotificationsCountState.value}
                  color="secondary"
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                >
                  <Notifications />
                </Badge>
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title="Nastavení účtu" sx={style.avatar}>
            <IconButton
              onClick={handleClickUser}
              size="small"
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            >
              <Avatar>{avatarName}</Avatar>
            </IconButton>
          </Tooltip>
        </LoadingGuard>
      </LoadingGuard>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={openUser}
        onClose={handleCloseUser}
        onClick={handleCloseUser}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={goToSettingsHandler}>
          <Box>
            <Typography sx={style.menuItemText}>
              {loggedUser.firstName ? loggedUser.firstName : loggedUser.email}{" "}
              {loggedUser.lastName ? loggedUser.lastName : ""}
            </Typography>
            <Typography sx={style.menuItemSubtext}>
              {loggedUser.email}
            </Typography>
          </Box>
        </MenuItem>
        <MenuItem onClick={ctx.doLogout}>
          <Typography sx={style.menuItemText}>Odhlásit</Typography>
        </MenuItem>
      </Menu>
      {myNotificationsState.value && (
        <Menu
          anchorEl={anchorElTwo}
          id="notification-menu"
          open={openNotification}
          onClose={handleCloseNotification}
          onClick={handleCloseNotification}
          PaperProps={{
            elevation: 0,
            sx: {
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              maxHeight: "90%",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: "400px",
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          {myNotificationsState.value.data.map((notification) => (
            <Box key={notification.id}>
              <MenuItem
                onClick={() =>
                  goToRequestAndMarkAsReadHandler(
                    notification.id,
                    notification.requestDTO.id
                  )
                }
              >
                <Stack direction="row" sx={style.notificationRow}>
                  <Notifications sx={style.iconNotification} />
                  {getNotificationDescription(notification, ctx.isLoggedAdmin)}
                </Stack>
              </MenuItem>
              <Divider />
            </Box>
          ))}
          <MenuItem onClick={goToNotificationsHandler}>
            <Stack direction="row">
              <MoreHorizRounded sx={style.iconNotification} />
              <Typography variant="body2">
                Zobrazit všechny notifikace
              </Typography>
            </Stack>
          </MenuItem>
          <Divider />
        </Menu>
      )}
    </Stack>
  );
};

export default Navigation;
