import {
  Apartment,
  CalendarToday,
  Inbox,
  Person,
  Settings,
} from "@mui/icons-material";
import { List } from "@mui/material";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

import AuthContext from "../contexts/AuthContext";
import { defineStyles } from "../../styles/defineStyles";

import { MenuItem } from "./MenuItem";

import "../../index.css";

const style = defineStyles({
  main: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    width: "auto",
  },
});

export const Menu: React.FC = () => {
  const { t } = useTranslation();
  const ctx = useContext(AuthContext);

  /* const api = useApiClient();

  useEffect(() => {
    api.findAllClients({}).then(console.log);
  }, [api]); */

  return (
    <div className="menu">
      <List sx={style.main}>
        <div className="logo_menu"></div>
        <MenuItem
          icon={<CalendarToday />}
          text={t("greentable.request.plural", "Žádosti")}
          route="/requests"
        />
        {ctx.isLoggedAdmin && (
          <MenuItem
            icon={<Inbox />}
            text={t("greentable.event.plural", "Události")}
            route="/events"
          />
        )}
        {ctx.isLoggedAdmin && (
          <MenuItem
            icon={<Person />}
            text={t("greentable.client.plural", "Klienti")}
            route="/clients"
          />
        )}
        {ctx.isLoggedAdmin && (
          <MenuItem
            icon={<Apartment />}
            text={t("greentable.room.plural", "Místnosti")}
            route="/rooms"
          />
        )}
        <div className="space"></div>
        <MenuItem
          icon={<Settings />}
          text={t("greentable.settings.plural", "Nastavení")}
          route="/settings"
        />
      </List>
    </div>
  );
};
