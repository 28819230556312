import { OpArgType, OpReturnType, TypedFetch } from "openapi-typescript-fetch";

// 404 neni chyba, man
export function safe404call<OP>(f: TypedFetch<OP>) {
  return async function (
    arg: OpArgType<OP>,
    init?: RequestInit
  ): Promise<OpReturnType<OP> | null> {
    let d;
    try {
      d = await f(arg, init);
      if (d.status == 404) return null;
      if (d.status < 300) return d.data;
    } catch (e: any) {
      if (e.getActualType) {
        const error = e.getActualType();
        if (error.status == 404) return null;
        throw error;
      }
      throw e;
    }
    throw d;
  };
}
