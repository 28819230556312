import { useTranslation } from "react-i18next";

export const useMaxLengthValidation = (chars: number) => {
  const { t } = useTranslation();

  return {
    maxLength: {
      value: chars,
      message: t(
        "common.error.maxLength",
        "Maximální povolená délka je {{max}} znaků",
        { max: chars }
      ),
    },
  };
};
