import { Add } from "@mui/icons-material";
import { Button, Stack, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

import { defineStyles } from "../../styles/defineStyles";

const EmptyRequestContent: React.FC = () => {
  const styles = defineStyles({
    main: {
      mt: 14,
      width: "100%",
      alignItems: "center",
    },
    subtitle: {
      mt: 3,
      mb: 3,
      width: "311px",
      color: "rgb(0,0,0,0.6)",
    },
    button: {
      height: "36px",
      ml: 1,
      alignItems: "center",
    },
    addText: {
      ml: 1,
    },
  });
  return (
    <Stack direction="column" sx={styles.main}>
      <Typography variant="h2">Vytvořte novou žádost</Typography>
      <Typography variant="body1" sx={styles.subtitle}>
        Rezervaci termínu v požedované místnosti začněte vytvořením nové
        žádosti.
      </Typography>
      <Link to="/requests/new" style={{ textDecoration: "none" }}>
        <Button variant="contained" sx={styles.button}>
          <Add />
          <Typography sx={styles.addText}>Nová žádost</Typography>
        </Button>
      </Link>
    </Stack>
  );
};

export default EmptyRequestContent;
